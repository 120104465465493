

.MedicalHistoryRouteNavBar {
    z-index: 2;
}

.MedicalHistoryRouteTitle {
    align-self: center;
    padding-block-end: 1rem;
    margin-inline: 5%;
    margin-block-start: var(--topPageMargin);
}

.MedicalHistoryRouteForm{
    padding-block: 0.5rem;
    margin-inline: 5%;
}

.MedicalHistoryRouteFooter {
    margin-block-start: 3rem;
}
/*
 @media (max-width: 768px) {
    .MedicalHistoryRecommenderPath {
        height: 55vh;
        margin: 10vh 0vh 0vh 4vh;
    }

    .MedicalHistoryRoutePage {
        grid-template-columns: 30vh auto;
    
        }

    .MedicalHistoryRouteForm{
        padding: 3vh 3vw;
    }

    .MedicalHistoryRouteTitle {
        align-self: center;
        padding: 3vh 3vw;
    }

}

@media (max-width: 425px) {
    .MedicalHistoryRecommenderPath {
        visibility: collapse;
    } 
    .MedicalHistoryRouteTitle {
        align-self: center;
        grid-area: 2 / 1 / 3 / 3;
        padding: 3vh 3vw;
    }
    
    .MedicalHistoryRouteForm{
        grid-area: 3 / 1 / 4 / 3; 
        padding: 3vh 3vw;
    }
} */