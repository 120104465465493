.NavbarFull {
    width: 100%;
    border-radius: 15px;
    top: 0;
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 20;
    transition: top 0.3s;
}

.NavbarPurpleUpBar {
    height: 30px;
    background-color: #330066;
}

.NavbarPurpleUpBarInner {
    display: flex;
    justify-content: left;
    padding-inline: 4vw;
}

.NavbarPurpleUpBarInnerButton {
    text-align: center;
    color: white;
    font-size: var(--buttonText);
    padding-block: 3px;
    padding-inline: 16px;
    cursor: pointer;
}

.NavbarPurpleUpBarInnerButton:hover {
    opacity: 0.9;
    background-color: rgba(204, 204, 255, 0.2)
}

.NavbarFullBackGradient {
    background: linear-gradient(0deg, rgba(251,255,255,0) 21%, rgba(251,255,255,1) 90%);
    padding-block: 1rem;
}

.NavbarFullInner {
    height: 80px;
    background-color: #CFF9FF;
    border-radius: 15px;
    max-width: 1300px;
    margin-inline: auto;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #CCCCFF;
}

.NavbarMenuOpen {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom: 0px;
}

.NavbarFullInnerLeftSideLogoYMC {
    height: 34px;
    width: auto;
    margin-block: 23px;
    margin-inline-start: 1vw;
    cursor: pointer;
}

.NavbarFullInnerMiddleButtons {
    display: flex;
    width: fit-content;
    max-width: 650px;
}

.NavbarFullInnerMiddleIndividualButton {
    font-size: var(--buttonText);
    padding-inline: 1vw;
    cursor: pointer;
    padding-block: 28px;
    color: #330066;
}

.NavbarFullInnerRightSide {
    display: flex;
    margin-inline: 2vw;
    margin-block: 10px;
}

.NavbarFullInnerRightSideProfileButton {
    width: 60px;
    height: 60px;
    background-color: white;
    border-radius: 15px;
    margin-inline: 0.8vw;
    position: relative;
    cursor:pointer;
    border: 1px solid white;
}

.NavbarFullInnerRightSideProfileIcon {
    position: absolute;
    width: 30px;
    margin-inline: 25%;
    margin-block: 25%;
    height: auto;
}

.NavbarFullInnerRightSideMedOrderButton {
    background-color: #6633ff;
    color: white;
    font-size: var(--buttonText);
    padding-block: 18px;
    padding-inline: 20px;
    border-radius: 12px;
    display: flex;
    width:fit-content;
    cursor: pointer;
    text-align: center;
    border: 1px solid #6633FF;
    position: relative;
}

.NavbarFullInnerRightSideMedOrderNotification {
    background-color: #10DFFF;
    color: #330066;
    width: 20px;
    height: 18px;
    border-radius: 50%;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 5px;
    right: 0.2vw;
}

.NavbarFullInnerRightSideExamIcon {
    height: 20px;
    width: auto;
    margin-block: auto;
    margin-inline-end: 3px;
    margin-inline-start: 10px;
}

.NavbarFullInnerRightSideCartIcon {
    height: 20px;
    width: auto;
    margin-block: auto;
}

.NavbarFullInnerRightSideBurgerIcon {
    padding-block: 18px;
    margin-inline-start: 2vw;
    display: none;
    cursor: pointer;
}

.NavbarBurgerIconBar1, .NavbarBurgerIconBar2, .NavbarBurgerIconBar3 {
    width: 22px;
    height: 5px;
    border: 2px solid #6633FF;
    margin: 3px 0;
    transition: 0.4s;
}

.NavbarBurgerIconBar1 {
    opacity: 50%;
}

.change .NavbarBurgerIconBar3 {
    transform: translate(0, 8px) rotate(-45deg);
    opacity: 50%;
}
.change .NavbarBurgerIconBar1 {opacity: 0;}
.change .NavbarBurgerIconBar2 {
    transform: translate(0, -8px) rotate(45deg);
}

.NavbarFullInnerMiddleIndividualButton:hover {
    background-color: #EFEFFF;
}

.NavbarFullInnerMiddleIndividualButton:active {
    background-color: #CCCCFF;
}

.NavbarFullInnerRightSideProfileButton:hover {
    background-color: #EFEFFF;
    border: 1px solid #6633FF;
    transition: all 0.3s;
}

.NavbarFullInnerRightSideMedOrderButton:hover {
    background-color: #EFEFFF;
    transition: all 0.3s;
    color: #6633ff;
    border: 1px solid #6633FF;
}

.NavbarLowerBlockOptions {
    background-color: #CFF9FF;
    padding-inline: 2vw;
    padding-block-end: 1rem;
    height: 80vh;
    max-width: 1300px;
    margin-inline: 2vw;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-bottom: 2px solid #CCCCFF;
}

.NavbarLowerBlockOptionsWhiteBox {
    background-color: #EFEFFF;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 20px;
    overflow: hidden;
}

.NavbarLowerBlockOptionsIndividualButton,
.NavbarLowerBlockOptionsIndividualButton2 {
    color: #6633FF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 100%;
    width: 100%;
    font-size: var(--buttonText);
}

.NavbarLowerBlockOptionsIndividualButton2 {
    background-color: #FBFFFF;
    display: none;
}

.NavbarLowerBlockOptionsIndividualButton2Image {
    width: 30px;
    margin-block: auto;
    height: auto;
}

.NavbarLowerBlockOptionsRightArrow {
    width: 12px; 
    height: 12px; 
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    border-left: 14px solid #6633FF;
}

.NavbarLowerBlockOptionsIndividualButton:hover {
    cursor: pointer;
    background-color: #FBFFFF;
    transition: all 0.3s;
}

.NavbarLowerBlockOptionsIndividualButton2:hover {
    cursor: pointer;
    background-color: #EFEFFF;
    transition: all 0.3s;
}

.NavbarLowerBlockOptionsIndividualButton:active,
.NavbarLowerBlockOptionsIndividualButton2:active,
.NavbarFullInnerRightSideMedOrderButton:active,
.NavbarFullInnerRightSideProfileButton:active {
    background-color: #CCCCFF;
}

.NavbarBeginSessionPrice {
    display: none;
}

@media (max-width: 1330px) {
    .NavbarFullInner {
        margin-inline: 2%;
    }
}

@media (max-width: 950px) {
    .NavbarFullInnerMiddleButtons {
        display: none;
    }

    .NavbarFullInnerRightSideBurgerIcon {
        display: block;
    }

    .NavbarBeginSession {
        display: none;
    }

    .NavbarBeginSessionPrice {
        display:block;
        margin-block: auto;
    }

    .NavbarMedicalOrderButton {
        padding-block: 0.4rem;
        font-size: 1.2rem;
    }
}

@media (max-width: 630px) {
    .NavbarProfileButton {
        padding-block: 0.2rem;
        font-size: 0.8rem;
        padding-inline: 0.5rem;
        margin-block: 0.4rem;
    }

    .NavbarMedicalOrderButton {
        padding-block: 0.2rem;
        font-size: 0.8rem;
        padding-inline: 0.5rem;
        margin-block: 0.4rem;
    }

    .NavbarMedicalOrderNotification {
        top: -0.2rem;
        right: -0.6rem;
        padding-inline: 0.4rem;
        font-size: 0.8rem;
    }

    .NavbarInnerLineSkip {
        display: block;
    }
}

@media (max-width: 540px) {
    .NavbarFullInnerRightSideProfileButton {
        display: none;
    }

    .NavbarLowerBlockOptionsIndividualButton2 {
        display: flex;
    }

    .NavbarFullInnerLeftSideLogoYMC {
        height: 26px;
        margin-block: 27px;
        margin-inline-start: 4vw;
        cursor: pointer;
    }
    
    .NavbarBeginSession {
        display: none;
    }

    .NavbarFullInnerRightSideBurgerIcon {
        padding-inline: 2vw;
    }
}

@media (max-width: 320px) {
    .NavbarFullInnerRightSideExamIcon,
    .NavbarFullInnerRightSideCartIcon {
        display: none;
    }
}