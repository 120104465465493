.MedicalHistoryFormDiv {
    align-items: start;
    color: #340066;
    font-weight: 600;
    max-width: 800px;
    margin-inline: auto;
    margin-block-end: 3rem;
}

.ButtonsMedHis {
    display: grid;
    align-content: space-evenly;
    grid-template-columns: 1fr 1fr;
    gap: 2vw;
}

.MedicalHistoryFormTitle {
    font-size: 3.1vh;
    color: #6633FF;
    font-weight: 600;
    line-height: 3.3vh;
}

.MedicalHistoryFormButtons {
    display: grid;
    align-content: space-evenly;
    grid-template-columns: auto auto auto;
    gap: 1vw;
    padding-block: 4vh;
    user-select: none;
    text-align: center;
}

@media (max-width: 720px) {
    .MedicalHistoryFormButtons {
        grid-template-columns: auto auto;
    }
}

@media (max-width: 550px) {
    .MedicalHistoryFormButtons {
        grid-template-columns: auto;
    }

    .MedicalHistoryFormTitle {
        font-size: 2.6vh;
        line-height: 2.9vh;
    }
}
/*
@media (max-width: 768px) {

    .MedicalFormIndividualButton {
        font-size: 2vw;
        padding-bottom: 7vw;
        color: #340066;
    }

    .MedicalHistoryFormButtons {
        grid-template-columns: auto auto auto;
    }

    .MedicalHistoryFormTitle {
        font-size: 3vw;
        padding: 2vh 0vh;
        color: #6633FF;
        font-weight: 600;
    }

    .MedicalHistoryFormDiv {
        height: 70vh;
    align-items: start;
        grid-template-rows: 13vh auto auto;
    }


}

@media (max-width: 425px) {
    .MedicalHistoryFormButtons {
        grid-template-columns: auto auto auto;
    }

    .MedicalFormIndividualButton {
        font-size: 3vw;
        padding: 8vw 3vw 12vw 3vw;
    }

    .MedicalHistoryFormTitle {
        font-size: 5vw;
    }
 
}*/