.ExamRecommendationRoutePage {
    align-items: top;
    background-color: #FBFFFF;
}

.ExamRecommendationRouteNavBar {
    z-index: 2;
    top: 0;
}

.RoutesBar {
    z-index: 2;
    position: fixed;
    width: 100%;
}

.ExamRecommendationRouteContent {
    max-width: 1140px;
    margin-inline: auto;
}

.ExamRecommendationRouteTitle {
    align-self: center;
    margin-inline: 5%;
    margin-block-start: 3vh;
}

.ExamRecommendationRouteTitleText {
    font-weight: 600;
    margin-block: 2vh;
    font-family: 'Meltmino', sans-serif;
    color:#6633FF;
}

.ExamRecommendationRouteFlexedParts {
    display: flex;
    flex-flow: row wrap;
    margin-inline: 2%;
    margin-block-end: 2rem;
}

.ExamRecommendationRouteLeftSide {
    width: 50%;
}

.ExamRecommendationRouteBasket {
    width: 50%;
}

.ExamRecommendationRoutePacks {
    align-self: center;
    max-width: 1140px;
    margin-inline: auto;
    margin-block-start: 3vh;
}

.ExamRecommendationRouteFormEmpty {
    margin-inline: 2%;
}

@media (max-width: 920px) {
    .ExamRecommendationRouteLeftSide,
    .ExamRecommendationRouteBasket {
        width: 100%;
    }

    .ExamRecommendationRouteTitle,
    .ExamRecommendationRouteFlexedParts {
        margin-inline: 2%;
    }
}
/*
@media (max-width: 1024px) {
    .ExamRecommendationRouteTitle {
        grid-area: 10 / 17 / 13 / 37;
    }
}
@media (max-width: 768px) {
    .RecommenderPath {
        grid-area: 8 / 2 / 31 / 17;
        height: 63.3vh;
        padding: 1rem 0rem;
        visibility: collapse;
    }
    
    .ExamRecommendationRouteTitle {
        grid-area: 10 / 3 / 13 / 29;
        align-self: center;
    }
    
    .ExamRecommendationRouteBasket {
        grid-area: 10/ 30 / -3 / 57;
        max-height: 120vh;
    }
    
    .ExamRecommendationRouteForm {
        grid-area: 13/ 3 / -3 / 29;
        max-height: 120vh;
        overflow: hidden;
    }
}

@media (max-width: 425px) {
    .ExamRecommendationRouteTitle {
        grid-area: 10 / 3 / 13 / -3;
        align-self: center;
    }
    
    .ExamRecommendationRouteBasket {
        grid-area: 60/ 3 / -3 / -3;
        max-height: 120vh;
    }
    
    .ExamRecommendationRouteForm {
        grid-area: 13/ 3 / -3 / -3;
        max-height: 120vh;
        overflow: hidden;
    }
}*/