.HeartAgeCalculatorFull {
    padding-block-start: 1rem;
    padding-inline: 2%;
}

.HeartAgeCalculatorInnerDiv {
    padding-block-end: 1rem;
    max-width: 1300px;
    margin-inline: auto;
    display: flex;
    color: #330066;
    overflow-x: hidden;
}

.HeartAgeCalculatorLeftSide {
    width: 60%;
}

.HeartAgeCalculatorLeftSideUpTitle {
    background-color: #CCCCFF;
    width:fit-content;
    padding-block: 0.75rem;
    padding-inline: 1rem;
    border-radius: 10px;
    font-weight: 600;
    font-size: var(--generalText);
    text-align: center;
}

.HeartAgeCalculatorLeftSideTitle {
    margin-block: 1rem;
    font-size: 2.3rem;
    font-weight: 700;
    color: #6633FF;
    font-family: 'Meltmino', sans-serif;
    line-height: 2.8rem;
}

.HeartAgeCalculatorLeftSideSubtitle {
    margin-block-start: 1rem;
    font-weight: 600;
    font-size: 0.9rem;
    color: #330066;
}

.HeartAgeCalculatorLeftSideImage {
    width: 85%;
    margin-inline: auto;
    position: relative;
}

.HeartAgeCalculatorRightSide {
    width: 40%;
    margin-inline-start: 2%;
}

.HeartAgeCalculatorRightSideTitleDiv,
.HeartAgeCalculatorResultsTitleDiv {
    background-color: #CFF9FF;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding-inline: 1rem;
    padding-block: 0.8rem;
    max-width: 1300px;
    margin-inline: auto;
}

.HeartAgeCalculatorRightSideTitleFlex {
    display: flex;
    padding-inline-end: 1%;
}

.HeartAgeCalculatorRightSideTitleIcon {
    width: 50px;
    height: 50px;
    margin-block: auto;
}

.HeartAgeCalculatorRightSideTitleInnerDiv {
    margin-block: auto;
}

.HeartAgeCalculatorRightSideUpTitle {
    font-family: 'Meltmino', sans-serif;
    color: #6633FF;
    font-size: 1rem;
    font-weight: 500;
    margin-inline-start: 0.5rem;
}

.HeartAgeCalculatorRightSideTitle {
    font-family: 'Meltmino', sans-serif;
    color: #6633FF;
    font-size: 1.7rem;
    font-weight: 600;
    margin-block: auto;
    margin-inline-start: 0.5rem;
}

.HeartAgeCalculatorResultsRightSideWhatIfConsequence {
    color: #330066;
    font-size: var(--generalText);
}

.HeartAgeCalculatorResultsRightSideWhatIfConsequenceHidden {
    display: none;
}

.HeartAgeCalculatorResultsRightSideWhatIfConsequenceTextOption {
    font-weight: 600;
    cursor:pointer;
}

.HeartAgeCalculatorRightSideSurveyTitle {
    margin-block-start: 1.5rem;
    padding-block-end: 0.9rem;
    text-align: center;
    font-size: 0.8rem;
    max-width: 25rem;
    margin-inline: auto;
    color: #330066;
}

.HeartAgeCalculatorRightSideSurveyQ1 {
    margin-block: 1rem;
    color: #330066;
    font-weight: 600;
    font-size: 0.9rem;
}

.HeartAgeCalculatorRightSideSurveyQ1SuggestionText {
    margin-block-end: 1rem;
    color: #330066;
    font-size: 0.9rem;
    max-height: 500px;
    overflow: hidden;
    transition: 0.3s;

    &.InformationCollapsed {
        max-height: 0px;
        transition: 0.1s;
        margin-block-end: 0rem;
    }
}

.HeartAgeCalculatorRightSideSurveyQ1SuggestionLink {
    text-decoration: underline;
    cursor: pointer;
}

.HeartAgeCalculatorRightSideSurveyQ1AgeInput {
    width: 9rem;
}

.HeartAgeCalculatorRightSideSurveyQ1Flex,
.HeartAgeCalculatorRightSideSurveyQ1TileFlex {
    display: flex;
}

.HeartAgeCalculatorRightSideSurveyQ1Tile {
    width: 50%;
    text-align: center;
    border-bottom: 1px solid #BFD4E4;
    padding-block: 0.5rem;
    padding-inline: 0.5rem;
    font-size: 0.9rem;
    cursor: pointer;
}

.HeartAgeCalculatorRightSideSurveyQ1Tile:not(.HeartAgeCalculatorRightSideSurveyQ1ActiveTile):hover {
    background-color: rgba(207, 249, 255, .5);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.HeartAgeCalculatorRightSideSurveyTimeInput {
    margin-block: auto;
}

.HeartAgeCalculatorRightSideSurveyQ1ActiveTile {
    background-color: #F2F2FF;
    color: #330066;
    border-bottom: 3px solid #6633FF;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    font-weight: 600;
    cursor: default;
}

.HeartAgeCalculatorRightSideSurveyQ1TileIcon {
    width: 50px;
    height: 50px;
    opacity: 0.3;
}

.HeartAgeCalculatorRightSideSurveyQ1TileActiveIcon {
    opacity: 1;
}

.HeartAgeCalculatorRightSideSurveyQuestionsFlex {
    display: flex;
    justify-content: space-between;
}

.HeartAgeCalculatorRightSideSurveyQuestion {
    width: 48%;
}

.HeartAgeCalculatorRightSideSurveyQ1MeasureFlex {
    display: flex;
}

.HeartAgeCalculatorRightSideSurveyQ4InputTile {
    padding-inline: 1rem;
    padding-block: 0.5rem;
    width: 100%;
}

.HeartAgeCalculatorRightSideSurveyQ4InputMeasure {
    padding-inline-start: 0.5rem;
    margin-block: auto;
}

.HeartAgeCalculatorRightSideSurveyCalculate,
.HeartAgeCalculatorRightSideSurveyCalculateDisabled {
    background-color: #6633FF;
    text-align: center;
    padding-inline: 0.2rem;
    color: white;
    font-size: 0.9rem;
    font-weight: 500;
    border: 2px solid #CCCCFF;
    border-radius: 10px;
    margin-block-start: 1rem;
    cursor: pointer;
    display: flex;
}

.HeartAgeCalculatorRightSideSurveyCalculate:hover {
    background-color: #CCCCFF;
    color: #6633ff;
    transition: all 0.3s;
    border: 2px solid #6633FF;
}

.HeartAgeCalculatorRightSideSurveyCalculateDisabled {
    cursor: default;
    background-color: #EFEFFF;
    color: #6633FF;
    border: 2px solid #6633FF;
}

.HeartAgeCalculatorResultsRightSideEmailButtonText {
    text-align: center;
    width: 90%;
    margin-block: auto;
}

.HeartAgeCalculatorResultsRightSideCalculatorIconDiv {
    padding-inline: 1rem;
    border-left: 1px solid white;
    padding-block: 1.5rem;
}

.HeartAgeCalculatorResultsRightSideCalculatorIcon {
    width: 2rem;
    height: 2rem;
}

.HeartAgeCalculatorBibliographyFlex {
    display: flex;
}

.HeartAgeCalculatorBibliography {
    font-size: 1.2rem;
    color:#330066;
}

.HeartAgeCalculatorBibliographyArrow {
    color:#330066;
    margin-block: auto;
}

.HeartAgeCalculatorBibliographyContent {
    font-size: 0.9rem;
    margin-block-start: 0.7rem;
    max-height: 500px;
    transition: all 0.5s ease;
    overflow: hidden;

    &.HeartAgeCalculatorBibliographyContentHidden {
        max-height: 0px;
        transition: all 0.5s ease;
        margin-block-start: 0rem;
    }
}

.HeartAgeCalculatorResultsTitleYMCLogo {
    width: 73px;
    height: auto;
    margin-block: auto;
}

.HeartAgeCalculatorResultsTitleIcon {
    width: 50px;
    height: 50px;
    margin-block: auto;
}

.HeartAgeCalculatorResultsRightSidePackBlock {
    background-color: #EFEFFF;
    border: 1px solid #CFF9FF;
    border-radius: 10px;
    padding-block: 1rem;
    padding-inline: 7%;
    margin-block-start: 1rem;
}

.HeartAgeCalculatorResultsRightSidePackBlockExamIcon {
    width: 50px;
    height: 50px;
    margin-inline-start: 0.2rem;
}

.HeartAgeCalculatorResultsRightSidePackBlockUpTitle {
    color: #6633FF;
    letter-spacing: 1px;
    margin-block: 0.5rem;
    font-family: 'Meltmino', sans-serif;
}

.HeartAgeCalculatorResultsRightSidePackBlockTitle {
    color: #6633FF;
    letter-spacing: 1px;
    font-size: 1.4rem;
    line-height: 1.7rem;
    font-weight: 600;
    margin-block-end: 0.5rem;
    font-family: 'Meltmino', sans-serif;
}

.HeartAgeCalculatorResultsRightSidePackBlockSubtitle {
    color: #330066;
    font-size: 0.75rem;
    border-bottom: 1px solid #330066;
    padding-block-end: 0.5rem;
}

.HeartAgeCalculatorResultsRightSidePackBlockInnerPack {
    margin-block-start: 1rem;
    border-left: 2px solid #CCCCFF;
    padding-inline-start: 0.8rem;
    padding-block: 0.5rem;
    color: #330066;
}

.HeartAgeCalculatorResultsRightSidePackBlockInnerPackImage {
    width: 100%;
    height: 7rem;
    border-radius: 10px;
    overflow:hidden;
}

.HeartAgeCalculatorResultsRightSidePackBlockInnerPackTitle {
    font-size: 1.5rem;
    line-height: 1.7rem;
    font-weight: 600;
    letter-spacing: 1px;
    margin-block: 1rem;
    font-family: 'Meltmino', sans-serif;
}

.HeartAgeCalculatorResultsRightSidePackBlockInnerPackExamsFlex {
    display: flex;
    justify-content: space-between;
}

.HeartAgeCalculatorResultsRightSidePackBlockInnerPackExamsIconsFlex {
    display: flex;
}

.HeartAgeCalculatorResultsRightSidePackBlockInnerPackExamsIcon {
    width: 20px;
    height: 20px;
    margin-block: 1rem;
    margin-inline-end: 0.4rem;
}

.HeartAgeCalculatorResultsRightSidePackBlockInnerPackExamsFree {
    margin-block: auto;
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 1px;
    color: #6633FF;
}

.HeartAgeCalculatorResultsRightSidePackBlockInnerPackButtonFlex {
    display: flex;
    justify-content: center;
    border: 2px solid #CCCCFF;
    border-radius: 10px;
}

.HeartAgeCalculatorResultsRightSidePackBlockInnerPackButtonFlex:hover {
    cursor: pointer;
    background-color: #CCCCFF;
    transition: all 0.3s;
    border: 2px solid #6633FF;
}

.HeartAgeCalculatorResultsRightSidePackBlockInnerPackButtonText {
    color: #6633FF;
    margin-block: auto;
    font-weight: 500;
}

.HeartAgeCalculatorResultsRightSidePackBlockInnerPackButtonIcon {
    width: 20px;
    height: 20px;
    margin-block: 0.5rem;
}

/* Remodeled results */
.HeartAgeCalculatorLeftSideResultContainer {
    border: 1px solid #CFF9FF;
    background-color: #FBFFFF;
    color: #330066;
    padding-inline: 2vw;
    padding-block: 2rem;
    border-radius: 8px;
    margin-block-end: 1rem;
    width: 100%;
}

.HeartAgeCalculatorLeftSideResultPartName {
    font-family: 'Meltmino', sans-serif;
    color:#6633FF;
}

.HeartAgeCalculatorLeftSideResultTitle {
    font-family: 'Meltmino', sans-serif;
    color:#6633FF;
    font-weight: 700;
    line-height: 35px;
}

.HeartAgeCalculatorLeftSideResultHeartBlock {
    max-width: 350px;
    margin-inline: auto;
    border-radius: 12px;
    position: relative;
    height: 17rem;
    margin-block-start: 1rem;
    margin-block-end: 3rem;
}

.HeartAgeCalculatorLeftSideResultHeartBlockMeterBlock {
    position: absolute;
    bottom: -25px;
    left: -10px;
    width: 110%;
}

.HeartAgeCalculatorLeftSideResultHeartBlockMeter {
    width: 100%;
    height: 55px;
    position: relative;
}

.HeartAgeCalculatorLeftSideResultHeartBlockMeterFace {
    height: auto;
    width: 100px;
    bottom: -30px;
    position: absolute;
}

.HeartAgeCalculatorLeftSideResultHeartImage {
    height: 15rem;
    width: auto;
    position: absolute;
    left: -30px;
    top: 10px;
}

.HeartAgeCalculatorLeftSideResultHeeartBlockInnerBlockSmallScreen,
.HeartAgeCalculatorLeftSideResultHeeartBlockInnerBlock {
    padding-block: 1rem;
    padding-inline: 1rem;
    border: 1px solid #CFF9FF;
    border-radius: 20px;
    font-family: 'Meltmino', sans-serif;
    text-align: center;
}

.HeartAgeCalculatorLeftSideResultHeeartBlockInnerBlockSmallScreen {
    display: none;
}

.HeartAgeCalculatorLeftSideResultHeeartBlockInnerBlock {
    position: absolute;
    right: -30px;
    top: 40px;
}

.HeartAgeCalculatorLeftSideResultHeeartBlockInnerBlockText1 {
    font-size: var(--epigraphText);
}

.HeartAgeCalculatorLeftSideResultHeeartBlockInnerBlockText2 {
    font-size: 2.8rem;
    line-height: 3.2rem;
    font-weight: 800;
}

.HeartAgeCalculatorLeftSideResultHeeartBlockInnerBlockText3 {
    font-size: 1.5rem;
    font-weight: 600;
}

.HeartAgeCalculatorLeftSideResultsListed {
    font-family: 'Meltmino', sans-serif;
    text-align: center;
    font-size: var(--epigraphText);
}

.HeartAgeCalculatorLeftSideResultSubtext {
    text-align: center;
    font-size: var(--generalText);
    margin-block-start: 1rem;
    line-height: 17px;
}

.HeartAgeCalculatorLeftSideResultParameterBox {
    background-color: #FFFAEA;
    border: 4px solid #EFEFFF;
    border-radius: 20px;
    padding-block: 1.5rem;
    padding-inline: 4%;
}

.HeartAgeCalculatorLeftSideResultParameterBloodBlock {
    display: flex;
    justify-content: left;
    color:white;
    font-family: 'Meltmino', sans-serif;
    padding-inline: 1rem;
    padding-block: 0.5rem;
    margin-block-end: 0.5rem;
    border-radius: 12px;
}

.HeartAgeCalculatorLeftSideResultParameterTitle {
    font-family: 'Meltmino', sans-serif;
    color:#6633FF;
    font-weight: 600;
}

.HeartAgeCalculatorLeftSideResultParameterSubtitle {
    font-family: 'Meltmino', sans-serif;
    color:#6633FF;
    font-size: var(--epigraphText);
    line-height: 22px;
    margin-inline-start: 1rem;
}

.HeartAgeCalculatorLeftSideResultParameterHorizontalLine {
    width: 100%;
    border-top: 1px solid #6633FF;
    margin-block: 0.5rem;
}

.HeartAgeCalculatorLeftResultParameterCardiacIcon {
    width: 40px;
    height: auto;
    margin-block: auto;
    margin-inline: 0.5rem;
}

.HeartAgeCalculatorLeftSideResultParameterIMCBlock {
    display: flex;
    justify-content: left;
    color:#6633FF;
    font-family: 'Meltmino', sans-serif;
}

.HeartAgeCalculatorLeftSideResultParameterIMCBlockInnerFlex {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.HeartAgeCalculatorLeftSideResultParameterIMCBlockInnerBlock {
    padding-inline-start: 1rem;
    margin-block: auto;
}

.HeartAgeCalculatorLeftSideResultParameterIMCBlockInnerBlockText1 {
    font-weight: 600;
    font-size: 1.2rem;
    margin-block-end: 0px;
}

.HeartAgeCalculatorLeftSideResultParameterIMCBlockInnerBlockText2 {
    font-weight: 600;
    font-size: 1.1rem;
}

.HeartAgeCalculatorLeftSideResultParameterLowerText {
    font-family: 'Meltmino', sans-serif;
    color:#6633FF;
    font-weight: 600;
    text-align: center;
    margin-block-start: 1rem;
}

.HeartAgeCalculatorLeftSideResultTitleBlueRadial {
    background: rgb(40,174,255);
    background: radial-gradient(circle, rgba(40,174,255,1) 0%, rgba(41,10,255,1) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.HeartAgeCalculatorLeftSideResultHealthFactorsColumns {
    width: 100%;
    height: auto;
}

.HeartAgeCalculatorLeftSideResultHealthFactorsSubtext {
    font-size: var(--generalText);
    margin-block-start: 2rem;
    line-height: 17px;
}

.HeartAgeCalculatorLeftSideResultHealthFactorsFlex {
    display: flex;
    margin-block-start: 1rem;
}

.HeartAgeCalculatorLeftSideResultHealthFactorsImage {
    width: 150px;
    height: auto;
    margin-inline-end: 2%;
    margin-block-end: auto;
}

.HeartAgeCalculatorLeftSideResultHealthFactorsLastText {
    font-family: 'Meltmino', sans-serif;
    font-size: var(--questionsText);
    text-align: center;
    margin-block-start: 3rem;
    margin-block-end: 1rem;
}

.HeartAgeCalculatorLeftSideResultHealthFactorsRightSideUpNumber {
    color: #28AEFF;
    font-weight: 700;
    font-family: 'Meltmino', sans-serif;
    margin-block-end: 0.5rem;
}

.HeartAgeCalculatorLeftSideResultHealthFactorsRightSideTitle {
    font-weight: 700;
    font-size: 1rem;
    font-family: 'Meltmino', sans-serif;
}

.HeartAgeCalculatorLeftSideResultHealthFactorsRightSideSubtitle {
    font-size: var(--generalText);
    margin-block-start: 0.5rem;
}

.HeartAgeCalculatorLeftSideResultInnerContainer {
    max-width: 600px;
    margin-inline: auto;
}

.HeartAgeCalculatorLeftSideResultContainer2 {
    display: flex;
    max-width: 1000px;
    margin-inline: auto;
    margin-block-start: 3rem;
}

.HeartAgeCalculatorLeftSideResultTextColumn {
    width: 50%;
    padding-inline: 0.5rem;
    font-size: var(--generalText);
}

.HeartAgeCalculatorLeftSideResultReadOption {
    text-align: center;
    font-weight: 700;
    margin-block-start: 2rem;
    color:#6633FF;
    cursor: pointer;
}

@media (max-width: 880px) {
    .HeartAgeCalculatorInnerDiv {
        flex-flow: row wrap;
        max-width: 500px;
    }

    .HeartAgeCalculatorRightSide {
        margin-inline-start: 0%;
    }

    .HeartAgeCalculatorResultsRightSideWhatIfConsequence {
        margin-block-end: 1rem;
    }

    .HeartAgeCalculatorLeftSideUpTitle {
        margin-inline: auto;
    }

    .HeartAgeCalculatorLeftSide,
    .HeartAgeCalculatorRightSide {
        width: 100%;
    }

    .HeartAgeCalculatorRightSide {
        margin-block-end: 1rem;
    }

    .HeartAgeCalculatorLeftSideResultTitle,
    .HeartAgeCalculatorLeftSideResultPartName {
        text-align: center;
    }

    .HeartAgeCalculatorLeftSideSubtitle {
        margin-block-end: 1rem;
    }

    .HeartAgeCalculatorLeftSideImage {
        display: none;
    }

    .HeartAgeCalculatorResultsRightSide {
        width: 100%;
        margin-inline-end: 0px;
    }

    .HeartAgeCalculatorResultsRightSide {
       margin-block-start: 2.5rem;
    }
}

@media (max-width: 550px) {
    .HeartAgeCalculatorLeftSideResultContainer2 {
        flex-flow: row wrap;
    }

    .HeartAgeCalculatorLeftSideResultTextColumn {
        width: 100%;
    }
}

@media (max-width: 450px) {

    .HeartAgeCalculatorLeftSideResultHeeartBlockInnerBlockSmallScreen {
        display: block;
    }
    
    .HeartAgeCalculatorLeftSideResultHeeartBlockInnerBlock {
        display: none;
    }

    .HeartAgeCalculatorLeftSideResultHeartImage {
        left: 0;
        right: 0;
        margin: 0 auto
    }

    .HeartAgeCalculatorLeftSideResultHeartBlockMeterBlock {
        left: -15px;
    }

    .HeartAgeCalculatorLeftSideResultHeartBlockMeterFace {
        width: 70px;
        bottom: -10px;
    }

    .HeartAgeCalculatorLeftSideResultParameterIMCBlockInnerFlex {
        flex-flow: row wrap;
    }

    .HeartAgeCalculatorLeftSideResultParameterIMCBlockInnerBlock {
       width: 100%;
       margin-block: 0.5rem;
    }

    .HeartAgeCalculatorLeftSideResultHealthFactorsImage {
        width: 100px;
    }

    .HeartAgeCalculatorLeftSideTitle {
        font-size: 1.7rem;
        line-height: 2rem;
    }

    .HeartAgeCalculatorRightSideSurveyQuestionsFlex {
        flex-flow: row wrap;
    }

    .HeartAgeCalculatorRightSideSurveyQuestion,
    .HeartAgeCalculatorRightSideSurveyQ1AgeInput {
        width: 100%;
    }

    .HeartAgeCalculatorRightSideUpTitle {
        font-size: 0.8rem;
    }

    .HeartAgeCalculatorRightSideTitle {
        font-size: 1rem;
    }

    .HeartAgeCalculatorResultsTitleDiv {
        padding-inline: 0.5rem;
    }

    .HeartAgeCalculatorResultsTitleYMCLogo {
        width: 50px;
    }

    .HeartAgeCalculatorResultsTitleIcon {
        height: 35px;
        width: 35px;
    }

    .HeartAgeCalculatorRightSideSurveyTimeInput {
        width: 100%;
    }

    .HeartAgeCalculatorRightSideSurveyQ1YearsInput {
        width: 100%;
        padding-inline: 1rem;
    }
}