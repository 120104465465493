.OrderConfirmedCreator {
  margin-block: 5rem;
  padding-block-start: var(--topPageMargin);
}

.OrderPageFooter {
  margin-block-start: 9rem;
}

.OrderConfirmedCreatorSpinner {
  border: 7px solid rgba(0, 0, 0, 0.1);
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  border-left-color: #340066;
  margin-inline: auto;

  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
} 

.ThankYouMessage {
  text-align: center;
  color: #340066;
  color: #6633ff;
  font-weight: 600;
  font-size: 3.2rem;
  padding: 2vh 17vw;
}

.ThankYouMessageSub {
  font-weight: 500;
  font-size: 2rem;
  margin-top: 30px;
  padding-inline: 10vw;
  text-align: center;
  color: #340066;
}

/* Updated Page Parts */
.OrderConfirmedPreviewButtons {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  max-width: 1140px;
  margin-inline: auto;
}

.OrderConfirmedPreviewButton {
  width: 30%;
  margin-inline: 1%;
  background-color: #6633ff;
  color: white;
  padding-block: 1vh;
  border: #6633ff solid 3px;
  font-weight: 600;
  border: 1px solid #9747FF;
}

.OrderConfirmedPreviewButton:hover,
.OrderConfirmedPreviewModalDownloadButton:hover,
.OrderConfirmedPreviewModalCloseButton:hover,
.OrderConfirmedPreviewModalFooterCloseButton:hover {
  background-color: #dcb3ff;
  color: black;
  cursor: pointer;
  transition: all 0.3s;
}

/* PDFs Made with HTML */
.OrderConfirmedPreviewModal {
  padding-block: 4vh;
}

.OrderConfirmedPreviewModalHeaderTitle {
  font-size: 4vh;
  text-align: center;
  margin-inline: auto;
}

.OrderConfirmedPreviewModalHeaderButtons {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-block: 2rem;
}

.OrderConfirmedPreviewModalDownloadButton,
.OrderConfirmedPreviewModalCloseButton,
.OrderConfirmedPreviewModalFooterCloseButton {
  width: 30%;
  background-color: #6633ff;
  color: white;
  text-align: center;
  padding-block: 0.5rem;
  margin-inline: 3vw;
  font-weight: 600;
  border-radius: 15px;
  border: 1px solid #9747FF;
}

.OrderConfirmedPreviewModalBody {
  padding-block: 4vh;
  margin-inline: 1vh;
}

.OrderConfirmedPreviewModalPDFContainer {
  border: 1px solid #F2F2FF;
  border-radius: 10px;
  width: 73%;
  margin-inline: auto;
  margin-block-end: 2vh;
  overflow-x: scroll;
}

.OrderConfirmedPreviewModalPDF {
  padding-inline: 3rem;
  padding-block: 2rem;
  width: 60rem;
}

.OrderConfirmedPreviewModalPDFUpperRow {
  display: flex;
  justify-content:space-between;
  border-bottom: 2px solid #BFD4E4;
  padding-block-end: 2rem;
}

.OrderConfirmedPreviewModalPDFUpperLeftSide {
  width: 50%;
  display: flex;
}

.OrderConfirmedPreviewModalPDFUpperLeftLogo {
  width: 20rem;
  height: auto;
  margin-block: auto;
}

.OrderConfirmedPreviewModalPDFUpperRightTexts {
  text-align: right;
  line-height: 1.3rem;
  margin-block: auto;
  color: #A3AED0;
}

.OrderConfirmedPreviewModalPDFUpperUpperRightOrderText {
  color: black;
  font-size: 1.3rem;
  margin-block-end: 0.5rem;
}

.OrderConfirmedPreviewModalPDFUpperUpperRightText1 {
  font-size: 0.8rem;
}

.OrderConfirmedPreviewModalPDFUpperUpperRightText2 {
  font-size: 0.9rem;
}

.OrderConfirmedPreviewModalPDFPatientData {
  margin-block-start: 0.5rem;
  border-bottom: 2px solid #BFD4E4;
  padding-block-end: 1rem;
}

.OrderConfirmedPreviewModalPDFPatientDataTitle,
.OrderConfirmedPreviewModalPDFExamsDataTitle {
  font-family:Andale Mono, sans-serif, 'Arial Narrow';
  font-size: 1.2rem;
  font-weight: 600;
}

.OrderConfirmedPreviewModalPDFPatientDataName {
  margin-block-start: 0.5rem;
}

.OrderConfirmedPreviewModalPDFPatientDataSecondRow {
  display: flex;
  justify-content: space-between;
}

.OrderConfirmedPreviewModalPDFExamsData {
  margin-block-start: 1rem;
  padding-block-end: 2rem;
  border-bottom: 2px solid #BFD4E4;
}

.OrderConfirmedPreviewModalPDFExamsDataUpperFlexDiv {
  display: flex;
  justify-content: space-between;
  margin-block-end: 1rem;
}

.OrderConfirmedPreviewModalPDFExamsDataLowerFlexDiv {
  display: flex;
  flex-flow: row wrap;
  margin-block-end: 0.5rem;
}

.OrderConfirmedPreviewModalPDFExamsDataOneExam {
  width: 50%;
}

.OrderConfirmedPreviewModalPDFExamsDataExamName {
  color: #330066;
  font-weight: 700;
  font-size: 0.9rem;
}

.OrderConfirmedPreviewModalPDFExamsDataExamIcon {
  width: 1rem
}

.OrderConfirmedPreviewModalPDFExamsDataExamPreparation {
  font-size: 0.9rem;
  color: #A3AED0;
  font-weight: 600;
}

.OrderConfirmedPreviewModalPDFSignatureDiv{
  text-align: center;
  margin-block-start: 2rem;
  padding-block-end: 2rem;
  border-bottom: 2px solid #BFD4E4;
}

.OrderConfirmedPreviewModalPDFSignatureImage {
  width: 20rem;
  margin-block-end: 1rem;
}

.OrderConfirmedPreviewModalPDFDoctorName {
  font-weight: 700;
  line-height: 1.4rem;

}

.OrderConfirmedPreviewModalPDFContactDiv {
  text-align: center;
  margin-block-start: 0.5rem;
  padding-block-end: 2rem;
  border-bottom: 2px solid #BFD4E4;
} 

.OrderConfirmedPreviewModalPDFContactFlex {
  display: flex;
  justify-content: center;
}

.OrderConfirmedPreviewModalPDFContactIcon {
  width: 1.5rem;
}

.OrderConfirmedPreviewModalPDFContactRight,
.OrderConfirmedPreviewModalPDFContactLeft {
  width: 15rem
}

.OrderConfirmedPreviewModalPDFContactLowerText {
  margin-block: auto;
}

.OrderConfirmedPreviewModalFooterCloseButton {
  margin-inline: auto;
}

.OrderConfirmedPreviewModalFooter {
  padding-block-end: 4vh;
}

@media (max-width: 720px) {
  .ThankYouMessage {
    padding-inline: 5vw;
    font-size: 2.5rem;
  }
  
  .ThankYouMessageSub {
    padding-inline: 5vw;
    font-size: 1.5rem;
  }

  .OrderConfirmedPreviewButton {
    width: 45%;
    margin-block: 1vh;
  }

  .OrderConfirmedPreviewModalPDFContainer {
    width: 95%;
  }
}

@media (max-width: 420px) {
  .OrderConfirmedCreator {
    margin-block: 3rem;
  }

  .OrderConfirmedPreviewButton {
    width: 95%;
  }

  .OrderConfirmedPreviewModalDownloadButton,
  .OrderConfirmedPreviewModalCloseButton,
  .OrderConfirmedPreviewModalFooterCloseButton {
    width: 80%;
    margin-block-end: 1vh;
  }
}