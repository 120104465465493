.ExamChoiceByRecomRoutePage {
    align-items: top;
}

.ExamChoiceByRecomRouteNavBar {
    z-index: 2;
}

.ExamChoiceByRecomAllTitleOuterDiv {
    margin-inline: 3%;
}

.ExamChoiceByRecomAllTitleDiv {
    height: 100%;
    justify-content: space-between;
    display: flex;
    border-bottom: 2px solid rgb(52, 0, 102);
    font-size: 3.2vh;
    font-weight: 600;
    max-width: 1140px;
    margin-inline: auto;
    margin-block-end: 1.5rem;
    color: #330066;
}

.ExamChoiceByRecomAll {
    display: flex;
    justify-content: space-around;
    max-width: 1140px;
    margin-inline: auto;
    margin-inline: 1%;
    padding-block-end: 2rem;
    color: #330066;
}

.ExamChoiceByRecomLeftSide,
.ExamChoiceByRecomRightSide {
    width: 48%;
    margin-inline: 1%;
    margin-block-end: 1rem;
}

.ExamChoiceByRecomLeftSide {
    border: 2px solid #F2F2FF;
    border-radius: 15px;
    height:fit-content;
}

.ExamChoiceByRecomPackBoxImage {
    width: 100%;
    height: 17rem;
    border-radius: 15px;
}

.ExamChoiceByRecomPackTextContainter {
    padding-inline: 5%;
}

.ExamChoiceByRecomPackTitle {
    text-align: center;
    font-family: 'Meltmino', sans-serif;
    margin-block: 1rem;
}

.ExamChoiceByRecomPackDescription,
.ExamChoiceByRecomPackProWarning {
    margin-block: 1.5rem;
    font-size: var(--generalText);
}

.ExamChoiceByRecomPackPriceFlex {
    display: flex;
    justify-content: space-between;
    margin-block-end: 2rem;
}

.ExamChoiceByRecomPackPrice {
    font-family: monospace, sans-serif;
    margin-block: auto;
    font-size: 1.5rem;
    margin-inline-end: 1rem;
    transition: all .2s ease-in-out;
}

.PriceChanged {
    font-size: 1.8rem;
}

.ExamChoiceByRecomPackAddToOrderButton,
.ExamChoiceByRecomPackAddToOrderButtonDisabled {
    background-color: #6633FF;
    border: 1px solid #CFF9FF;
    color: white;
    width: fit-content;
    padding-block: 0.5rem;
    padding-inline: 1rem;
    border-radius: 10px;
    font-size: 1.1rem;
    font-weight: 600;
    text-align: center;
}

.ExamChoiceByRecomPackAddToOrderButton:hover {
    background-color: #dcb3ff;
    color: black;
    cursor: pointer;
    transition: all 0.3s;
}

.ExamChoiceByRecomPackAddToOrderButtonDisabled {
    background-color: #ae9ce6;
}

.ExamChoiceByRecomUpperTags {
    display: flex;
    align-items: center;
}

.ExamChoiceByRecomFemaleTag,
.ExamChoiceByRecomMaleTag {
    width: 100%;
    text-align: center;
    justify-content: center;
    font-size: 1.2rem;
    font-weight: 500;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding-block: 0.5rem;
    background-color: #F2F2FF;
    border-top: 1px solid #CFF9FF;
    border-left: 1px solid #CFF9FF;
    border-right: 1px solid #CFF9FF;
    cursor: pointer;
}

.ExamChoiceByRecomActiveTag {
    background-color: #6633FF;
    color: white;
    cursor:default;
}

.ExamChoiceByRecomChoiceContainer {
    padding-inline: 2vh;
    padding-block: 2vh;
    border: 2px solid #F2F2FF;
    border-radius: 15px;
}

.ExamChoiceByRecomChoiceRightText {
    text-align: right;
    color: #330066;
    font-size: 2.3vh;
    letter-spacing: 2px;
    margin-block-end: 1rem;
}

.ExamChoiceByRecomChoiceDescription {
    padding-block: 1vh;
    font-size: var(--generalText);
}

.ExamChoiceByRecomChoiceLabel {
    font-weight: 500;
    font-size: 1.3rem;
    padding-block-start: 1rem;
    padding-block-end: 0.5rem;
    border-bottom: 2px solid;
}

.ExamChoiceByRecomChoiceExamsFlexed {
    display: flex;
    margin-block: 0.4rem;
}

.ExamChoiceByRecomChoiceExamSelect {
    margin-block: auto;
}

.ExamChoiceByRecomChoiceExamName,
.ExamChoiceByRecomChoiceChooseAllText {
    padding-inline-start: 1vw;
}

.ExamChoiceByRecomChoiceExamGroupName {
    padding-inline-start: 1vw;
    font-weight: 600;
}

.ExamChoiceByRecomChoiceExamPrice {
    color: #330066;
    font-weight: 600;
    margin-inline-start: 0.5rem;
}

.ExamChoiceByRecomChoiceExamPRO {
    background-color: #CFF9FF;
    color: #330066;
    border-radius: 10px;
    font-weight: 600;
    height: 1.2rem;
    padding-inline: 0.5rem;
    width:fit-content;
}

.ExamChoiceByRecomChoiceChooseAllDiv {
    margin-block-start: 1rem;
}

.ExamChoiceByRecomChoiceChooseAllText {
    font-size: 1.2rem;
}

.ExamChoiceByRecomChoiceExamInfo {
    background-color: #D9D9D9;
    margin-inline-start: 0.5rem;
    margin-block: auto;
    padding-inline: 0.4rem;
    line-height: 1.3rem;
    height: fit-content;
    border-radius: 5px;
    border: 1px solid;
    position: relative;
    cursor: pointer;
}

.ExamChoiceByRecomChoiceExamInfo .ExamChoiceByRecomChoiceTooltip {
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding-inline: 1vh;
    width: 25vh;
  
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    left: 110%;
  }
  
.ExamChoiceByRecomChoiceExamInfo:hover .ExamChoiceByRecomChoiceTooltip {
  visibility: visible;
}

.ExamChoiceByRecomChoiceSpecifics {
    max-height: 500px;
    overflow: hidden;
    transition: 0.3s;
    margin-inline-start: 1.5rem;
    font-size: 0.95rem;
    margin-block-end: 1.5rem;

    &.InformationCollapsed {
        max-height: 0px;
        transition: 0.1s;
        margin-block-end: 0rem;
    }
}

/* Exams display */
.ExamChoiceByRecomAllExamsDivContainer {
    margin-inline: 1rem;
}

.ExamChoiceByRecomAllExamsDiv {
    border: 2px solid #F2F2FF;
    border-radius: 15px;
    height:fit-content;
    max-width: 1140px;
    margin-inline: auto;
    margin-block: 3rem;
    padding-inline: 1rem;
    padding-block: 2rem;
}

.ExamChoiceByRecomAllExamsPageIcon,
.ExamChoiceByRecomAllExamsExamIcon {
    width: 1rem;
    height: 1rem;
    margin-block: auto;
}

.ExamChoiceByRecomAllExamsHorizontalLine {
    border-top: 1px solid #BFD4E4;
    width: 100%;
    margin-block: 0.3rem;
}

.ExamChoiceByRecomAllExamsFlexFullLine,
.ExamChoiceByRecomAllExamsFlexExamLine {
    display: flex;
    justify-content: space-between;
}

.ExamChoiceByRecomAllExamsFlexExamLine {
    width: 90%;
    border-right: 1px solid #CFF9FF;
}

.ExamChoiceByRecomAllExamsLeftArrowDiv,
.ExamChoiceByRecomAllExamsRightArrowDiv {
    width: 5%;
    text-align: center;
    cursor: pointer;
}

.ExamChoiceByRecomAllExamsLeftArrow,
.ExamChoiceByRecomAllExamsRightArrow {
    height: 1.2rem;
    margin-block: 2rem;
}

.ExamChoiceByRecomAllExamsSingleExamBlock {
    width: 100%;
    border-left: 1px solid #CFF9FF;
    padding-inline: 0.5rem;
    padding-block: 0.2rem;
    margin-block: 0.3rem;
}

.ExamChoiceByRecomAllExamsSingleExamBlockUpLine,
.ExamChoiceByRecomAllExamsDotsDiv {
    display: flex;
}

.ExamChoiceByRecomAllExamsSingleExamBlockName {
    margin-block: auto;
    font-weight: 600;
    color: #330066;
}

.ExamChoiceByRecomAllExamsSingleExamBlockDescr {
    color: #A3AED0;
    margin-block: 0.2rem;
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 1rem;
}

/* Pack Recommendations */
.ExamChoiceByRecomPacksDiv {
    justify-content: center;
    background-color: white;
    border: 1px solid #F2F2FF;
    border-radius: 10px;
    padding-block-start: 1rem;
    padding-block-end: 2rem;
    padding-inline: 2%;
    margin-inline: 1%;
    margin-block: 4rem;
    max-width: 1140px;
    margin-inline: auto;
}

.ExamChoiceByRecomPacksUpTitle {
    background-color: #CFF9FF;
    padding-block: 0.5rem;
    padding-inline: 1rem;
    border-radius: 10px;
    margin-inline: auto;
    width: fit-content
}

.ExamChoiceByRecomPacksTitle {
    text-align: center;
    font-size: 1.7rem;
    margin-block: 1rem;
    font-family: 'Meltmino', sans-serif;
    color:#330066;
}

.ExamChoiceByRecomPacksPackBoxesFlex,
.ExamChoiceByRecomPacksPackBoxesFullLineFlex {
    display: flex;
    justify-content: center;
}

.ExamChoiceByRecomPacksLeftArrowDiv,
.ExamChoiceByRecomPacksRightArrowDiv {
    cursor: pointer;
}

.ExamChoiceByRecomPacksLeftArrow,
.ExamChoiceByRecomPacksRightArrow {
    height: 1.8rem;
    width: 1.8rem;
    margin-block: 9rem;
    padding-inline: 1vw;
}

.ExamChoiceByRecomPacksPackBox {
    width: 75%;
    margin-inline: 1%;
    margin-block-end: 1rem;
    padding-block-start: 6rem;
    padding-block-end: 2rem;
    position: relative;
}

.ExamChoiceByRecomPacksPackBoxWhiteBox {
    margin-inline: 10%;
    background-color: white;
    border: 1px solid #F2F2FF;
    border-radius: 10px;
    padding-inline: 1.5rem;
    padding-block: 1.5rem;
    position: relative;
}

.ExamChoiceByRecomPacksPackBoxWhiteBoxTitle {
    line-height: 1rem;
    font-family: 'Meltmino', sans-serif;
    font-size: 1.1rem;
    color:#330066;
}

.ExamChoiceByRecomPacksPackBoxWhiteBoxDescription {
    line-height: 1.2rem;
    font-size: 0.9rem;
    margin-block: 1rem;
}

.ExamChoiceByRecomPacksPackBoxWhiteBoxFlex {
    display: flex;
    justify-content: space-between;
}

.ExamChoiceByRecomPacksPackBoxWhiteBoxPrice {
    font-family: monospace, sans-serif;
    margin-block: auto;
    font-size: 1rem;
    color:#330066;
}

.ExamChoiceByRecomPackBoxWhiteBoxMoreInfoButton {
    background-color: #6633FF;
    border: 1px solid #CFF9FF;
    color: white;
    width: 100%;
    padding-inline: 5%;
    padding-block: 0.3rem;
    margin-block-start: 0.7rem;
    border-radius: 10px;
    text-align: center;
}

.ExamChoiceByRecomPackBoxWhiteBoxMoreInfoButton:hover {
    background-color: #dcb3ff;
    color: black;
    cursor: pointer;
    transition: all 0.3s;
}

@media (max-width:720px) {
    .ExamChoiceByRecomAll {
        flex-flow: row wrap;
    }
    
    .ExamChoiceByRecomLeftSide,
    .ExamChoiceByRecomRightSide {
        width: 98%;
    }

    .ExamChoiceByRecomPackBoxImage {
        height: 10rem;
    }
}

@media (max-width: 620px) {
    .ExamChoiceByRecomPacksPackBox {
        width: 95%;
    }
}

@media (max-width: 420px) {
    .ExamChoiceByRecomAllExamsFlexExamLine {
        width: 80%;
    }
    
    .ExamChoiceByRecomAllExamsLeftArrowDiv,
    .ExamChoiceByRecomAllExamsRightArrowDiv {
        width: 10%;
    }
}