

.FamilyRouteNavBar {
    z-index: 2;
}

.FamilyRouteTitle {
    align-self: center;
    padding-block-end: 1rem;
    margin-inline: 5%;
    margin-block-start: var(--topPageMargin);
}

.FamilyRouteForm {
    padding-block: 0.5rem;
    margin-inline: 5%;
}

.FamilyRouteFooter {
    margin-block-start: 4rem;
}


/* .FamilyRouteNextButton {
    font-size: 1.3rem;
    grid-area: 34 / 38 / 37 / 55;
}

.FamilyRouteBackButton {
    font-size: 1.3rem;
    grid-area: 34 / 23 / 37 / 37;
} */

/*
@media (max-width: 768px) {
    .FamilyRouteRecommenderPath {
        height: 55vh;
        margin: 10vh 0vh 0vh 4vh;
    }

    .FamilyRoutePage {
        grid-template-columns: 30vh auto;
    
        }

    .FamilyRouteForm{
        padding: 3vh 3vw;
    }

    .FamilyRouteTitle {
        align-self: center;
        padding: 3vh 3vw;
    }

}

@media (max-width: 425px) {
    .FamilyRouteRecommenderPath {
        visibility: collapse;
    } 
    .FamilyRouteTitle {
        align-self: center;
        grid-area: 2 / 1 / 3 / 3;
        padding: 3vh 3vw;
    }
    
    .FamilyRouteForm{
        grid-area: 3 / 1 / 4 / 3; 
        padding: 3vh 3vw;
    }
} */