/* Contact */
.ContactPageContactFlex {
    display: flex;
    max-width: 1140px;
    margin-inline: auto;
    padding-inline: 1rem;
    padding-block: 2.5rem;
    color: #330066;
    padding-block-start: calc(var(--topPageMargin) + 1rem);
}

.ContactPageContactLeft {
    width: 60%;
}

.ContactPageContactInfoFlex {
    display: flex;
    margin-block-start: 2rem;
}

.ContactPageContactInfoFlex2 {
    display: flex;
    margin-block-start: 1rem;
}

.ContactPageContactInfoEmailIcon,
.ContactPageContactInfoPhoneIcon {
    width: 1.5rem;
    height: auto;
    margin-block: auto;
}

.ContactPageContactInfoEmail,
.ContactPageContactInfoPhone {
    margin-block: auto;
    color: #6633FF;
    font-weight: 500;
}

.ContactPageContactRight {
    width: 40%;
    margin-block: auto;
}

.ContactPageContactInputTile,
.ContactPageContactInputTileBig {
    border-radius: 12px;
    background-color: #EFEFFF;
    padding-block: 0.5rem;
    padding-inline: 0.75rem;
    width: 100%;
    color: #330066;
    margin-block-end: 0.5rem;
}

.ContactPageContactInputTileBig {
    height: 5rem;
}

::placeholder {
    color: #330066;
    opacity: 0.7; /* Firefox */
}
  
::-ms-input-placeholder { /* Edge 12 -18 */
    color: #330066;
}

.ContactPageContactSendButton,
.ContactPageContactSendButtonDisabled {
    width: 100%;
    background-color: #6633FF;
    color: white;
    border: 1px solid #330066;
    border-radius: 12px;
    text-align: center;
    padding-block: 0.5rem;
    padding-inline: 0.75rem;
    font-weight: 500;
}

.ContactPageContactSendButtonDisabled {
    background-color: #dcb3ff;
    color:#330066;
    cursor: default;
}

.ContactPageContactSendButton:hover {
    background-color: #dcb3ff;
    color:#330066;
    cursor: pointer;
    transition: all 0.3s;
}

.ContactPageContactSendError {
    color: red;
    margin-block-end: 0.5rem;
}

/* FAQ */
.ContactPageFAQ {
    display: flex;
    max-width: 1140px;
    margin-inline: auto;
    padding-block: 2rem;
    color: #330066;
}

.ContactPageFAQLeftSide {
    width: 50%;
    padding-inline: 1rem;
}

.ContactPageFAQUpTitle,
.ContactPageContactUpTitle {
    color: #330066;
    font-family: 'Meltmino', sans-serif;
    font-weight: 600;
    letter-spacing: 1px;
}

.ContactPageFAQTitle,
.ContactPageContactTitle {
    font-weight: 600;
    font-size: 3rem;
    margin-block: 1.5rem;
    letter-spacing: 1px;
    padding-inline-end: 1rem;
    color: #330066;
    font-family: 'Meltmino', sans-serif;
}

.ContactPageFAQSubtitle,
.ContactPageContactSubtitle {
    font-size: var(--generalText);
    max-width: 33rem;
}

.ContactPageFAQRightSide {
    width: 50%;
    padding-inline-end: 1rem;
}

.ContactPageFAQQuestionBlock {
    border: 1px solid #F2F2FF;
    background-color: #fcfcfc;
    padding-inline: 1rem;
    padding-block: 0.5rem;
    margin-block-end: 0.5rem;
}

.ContactPageFAQQuestionBlockQuestion {
    border-bottom: 2px solid black;
    padding-block: 0.5rem;
    font-family: 'Meltmino', sans-serif;
    display: flex;
}

.ContactPageFAQQuestionBlockArrowDiv {
    width: fit-content;
    margin-inline-end: 1rem;
    height:fit-content
}

.ContactPageFAQQuestionBlockArrow {
    transition: all 0.3s ease;
}

.FAQArrowDivRotation {
    transform: rotate(90deg);
    transition: all 0.3s ease;
}

.ContactPageFAQQuestionBlockAnswer {
    font-size: var(--generalText);
    margin-block-start: 0.7rem;
    max-height: 500px;
    transition: all 0.3s ease;
    overflow: hidden;

    &.ContactAnswerHidden {
        max-height: 0px;
        transition: all 0.3s ease;
        margin-block-start: 0rem;
    }
}

/* Newsletter */
.ContactPageNewsletter {
    padding-block: 5rem;
    padding-inline: 0.5rem;
    transform: rotate(180deg);
    color: #330066;
}

.ContactPageNewsletterContent {
    display: flex;
    max-width: 1140px;
    margin-inline: auto;
    padding-block: 2.5rem;
    justify-content: space-between;
    border-radius: 20px;
    border: 2px solid #CCCCFF;
    transform: rotate(180deg)
}

.ContactPageNewsletterLeftSide {
    width: 60%;
    padding-inline-start: 2rem;
}

.ContactPageNewsletterUpTitle {
    background-color: #CCCCFF;
    width:fit-content;
    padding-block: 0.7rem;
    padding-inline: 1rem;
    border-radius: 10px;
    letter-spacing: 1px;
}

.ContactPageNewsletterTitle {
    color: #6633FF;
    font-size: 3rem;
    font-weight: 700;
    padding-block: 1rem;
    line-height: 3.3rem;
    letter-spacing: 1px;
    font-family: 'Meltmino', sans-serif;
}

.ContactPageNewsletterSubtitle {
    color: #6633FF;
    font-size: var(--epigraphText);
    font-weight: 400;
}

.ContactPageNewsletterTOSFlex {
    display: flex;
    margin-block-start: 1rem;
}

.ContactPageNewsletterTOS {
    color: #330066;
}

.ContactPageNewsletterInputFlex {
    display: flex;
    margin-block-start: 1rem;
}

.ContactPageNewsletterInputTile {
    border-radius: 12px;
    background-color: #EFEFFF;
    padding-block: 1rem;
    padding-inline: 0.75rem;
    width: 100%;
    color: #330066;
    margin-block-end: 0.5rem;
    margin-inline-end: 0.5rem;
}

.ContactPageNewsletterDownloadButton,
.ContactPageNewsletterDownloadButtonDisabled {
    border-radius: 12px;
    border: 1px solid #9747FF;
    background-color: #10DFFF;
    padding-block: 1rem;
    padding-inline: 1.5rem;
    width: fit-content;
    margin-block-end: 0.5rem;
    margin-inline-end: 0.5rem;
    display: flex;
    cursor: pointer;
}

.ContactPageNewsletterDownloadButtonDisabled {
    background-color: #86eefe;
    cursor:default;
}

.ContactPageNewsletterDownloadButton:hover {
    background-color: #8eecff;
}

.ContactPageNewsletterDownloadIcon {
    width: 1rem;
    height: 1rem;
    margin-block: auto;
    margin-inline-start: 0.5rem;
}

.ContactPageNewsletterDownloadInputFail {
    color: red;
}

.ContactPageNewsletterRightSide {
    text-align: right;
}

.ContactPageNewsletterRightImage {
    width: 25rem;
}

@media (max-width:920px) {
    .ContactPageNewsletterInputFlex {
        flex-flow: row wrap;
    }

    .ContactPageNewsletterLeftSide {
        width: 100%;
        padding-inline: 2rem;
    } 
}

@media (max-width:770px) {
    .ContactPageNewsletterContent {
        flex-flow: row wrap;
        padding-block: 1.5rem;
    }

    .ContactPageNewsletterRightSide {
        display: none;
    }

    .ContactPageNewsletterInputTile {
        margin-inline-end: 0rem;
    }
}

@media (max-width:720px) {
    .ContactPageContactFlex {
        flex-flow: row wrap;
    }
    
    .ContactPageContactLeft,
    .ContactPageContactRight {
        width: 100%;
    }

    .ContactPageContactRight {
        margin-block-start: 2rem;
    }

    .ContactPageFAQ {
        flex-flow: row wrap;
    }

    .ContactPageFAQLeftSide,
    .ContactPageFAQRightSide {
        width: 100%;
    }

    .ContactPageFAQRightSide {
        padding-inline: 1rem;
        margin-block-start: 1rem;
    }
}

@media (max-width:420px) {
    .ContactPageNewsletterLeftSide {
        padding-inline: 0.5rem;
    } 

    .ContactPageNewsletterTitle {
        font-size: 2rem;
        line-height: 2.3rem;
    }
    
    .ContactPageNewsletterSubtitle {
        font-size: 1rem;
        line-height: 1.5rem;
    }
}