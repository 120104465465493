.ExamRecommendationRoutePacksDiv {
    justify-content: center;
    background-color: white;
    border: 1px solid #F2F2FF;
    border-radius: 10px;
    padding-block-start: 1rem;
    padding-block-end: 2rem;
    padding-inline: 2%;
    margin-inline: 1%;
    margin-block-end: 4rem;
    color: #330066;
}

.ExamRecommendationRoutePacksUpTitle {
    background-color: #CFF9FF;
    padding-block: 0.5rem;
    padding-inline: 1rem;
    border-radius: 10px;
    margin-inline: auto;
    width: fit-content;
    text-align: center;
    letter-spacing: 1px;
    font-weight: 600;
}

.ExamRecommendationRoutePacksTitle {
    text-align: center;
    font-size: 1.7rem;
    margin-block: 1rem;
    font-family: 'Meltmino', sans-serif;
}

.ExamRecommendationRoutePacksLeftArrowDiv,
.ExamRecommendationRoutePacksRightArrowDiv {
    cursor:pointer;
}

.ExamRecommendationRoutePacksLeftArrow,
.ExamRecommendationRoutePacksRightArrow {
    height: 1.8rem;
    width: 1.8rem;
    margin-block: 9rem;
    padding-inline: 1vw;
}

.ExamRecommendationRoutePacksPackBoxesFlex,
.ExamRecommendationRoutePacksPackBoxesFullLineFlex {
    display: flex;
    justify-content: center;
}

.ExamRecommendationRoutePacksPackBox {
    width: 80%;
    margin-inline: 1%;
    margin-block-end: 1rem;
    padding-block-start: 6rem;
    padding-block-end: 2rem;
    position: relative;
}

.ExamRecommendationRoutePacksPackBoxWhiteBox {
    margin-inline: 10%;
    background-color: white;
    border: 1px solid #F2F2FF;
    border-radius: 10px;
    padding-inline: 1.5rem;
    padding-block: 1.5rem;
    position: relative;
}

.ExamRecommendationRoutePacksPackBoxWhiteBoxTitle {
    font-family: monospace, sans-serif;
    line-height: 1rem;
}

.ExamRecommendationRoutePacksPackBoxWhiteBoxDescription {
    line-height: 1.2rem;
    font-size: 0.9rem;
    margin-block: 1rem;
}

.ExamRecommendationRoutePacksPackBoxWhiteBoxFlex {
    display: flex;
    justify-content: space-between;
}

.ExamRecommendationRoutePacksPackBoxWhiteBoxPrice {
    font-family: monospace, sans-serif;
    margin-block: auto;
    font-size: 1rem;
}

.ExamRecommendationRoutePacksPackBoxWhiteBoxMoreInfoButton {
    background-color: #6633FF;
    border: 1px solid #CFF9FF;
    color: white;
    width: 100%;
    padding-inline: 5%;
    padding-block: 0.3rem;
    margin-block-start: 0.7rem;
    border-radius: 10px;
    text-align: center;
}

.ExamRecommendationRoutePacksPackBoxWhiteBoxMoreInfoButton:hover {
    background-color: #dcb3ff;
    color: black;
    cursor: pointer;
    transition: all 0.3s;
}

@media (max-width: 920px) {
    .ExamRecommendationRoutePacksPackBox {
        width: 45%;
    }
}

@media (max-width: 620px) {
    .ExamRecommendationRoutePacksPackBox {
        width: 95%;
    }

    .ExamRecommendationRoutePacksLeftArrow,
    .ExamRecommendationRoutePacksRightArrow {
        height: 1.8rem;
        width: 1.3rem;
        margin-block: 9rem;
        padding-inline: 0.5vw;
    }
}