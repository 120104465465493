.PageFooter {
    background-color: #330066;
    color: white;
    padding-block: 3rem;
    padding-inline: 5%;
}

.PageFooterContent {
    max-width: 1140px;
    margin-inline: auto;
    border-bottom: 2px solid white;
    padding-block-end: 2rem;
}

.PageFooterUpperLogoDiv {
    text-align: center;
    margin-block: 2rem;
}

.PageFooterUpperLogo {
    width: 12rem;
    height: auto;
}

.PageFooterFlexedLinks {
    display: flex;
    flex-flow: row wrap;
    margin-inline: 10vw;
    margin-block-end: 0.5rem;
}

.PageFooterLeftLogoDiv {
    width: 25%;
}

.PageFooterLeftLogo {
    /*width: 15vw;*/
    max-width: 13rem;
    height: auto;
}

.PageFooterLeftTextBelowLogo {
    text-align: left;
    margin-block-start: 7vh;
    margin-block-end: 3vh;
    font-size: 1.5vh;
}

.PageFooterLinkColumnsFlex {
    width: 70%;
    display:flex;
    flex-flow: row wrap;
    padding-inline-start: 50px;
}

.PageFooterLinkColumn {
    width: 23%;
    margin-inline-start: 2%;
}

.PageFooterLinkColumnTitle {
    border-bottom: 1px solid white;
}

.PageFooterLinkColumnSingleLink {
    line-height: 1rem;
    font-size: 0.8rem;
    margin-inline-end: 2rem;
    margin-block: 0.5rem;
    cursor:pointer;
}

.PageFooterLinkColumnSingleLink_a {
    text-decoration: none;
    color: white;
}

.PageFooterBottomText {
    text-align: center;
    font-size: 0.75rem;
    font-weight: 300;
}

@media (max-width: 920px) {
    .PageFooterLeftLogo {
        max-width: 10rem;
    }
}

@media (max-width: 720px) {
    .PageFooterContent {
        margin-inline: 1vw;
    }

    .PageFooterFlexedLinks {
        margin-inline: 3vh;
    }

    .PageFooterLinkColumnSingleLink {
        margin-block: 1.5vh;
    }

    .PageFooterLeftLogo {
        max-width: 10rem;
    }
}

@media (max-width: 620px) {
    .PageFooterLeftLogoDiv {
        width: 35%;
    }

    .PageFooterLeftLogo {
        max-width: 11rem;
    }

    .PageFooterLinkColumn {
        width: 48%;
        margin-inline-start: 2%;
    }

    .PageFooterLinkColumnsFlex {
        width: 65%;
    }
}

@media (max-width: 520px) {
    .PageFooterLeftLogoDiv {
        width:100%;
        text-align: center;
        margin-inline: auto;
        margin-block-end: 2rem;
    }

    .PageFooterLinkColumnsFlex {
        width: 100%;
        padding-inline-start: 0px;
    }

    .PageFooterLinkColumnsFlex {
        margin-inline: auto;
        justify-content: space-around;
    }
}