.RemakeOrder {
  margin-block-start: calc(var(--topPageMargin) + 5rem);
}

.RemakeOrderPwdInput {
  border: 3px solid #9747FF;
  border-radius: 20px;
  width: 30rem;
  margin-inline: auto;
  text-align: center;
}

.RemakeOrderPwdInputTitle {
  padding-block: 0.7rem;
  font-size: 1.8rem;
  font-weight: 600;
}

.RemakeOrderPwdInputSubtitle {
  padding-block-start: 0.7rem;
  padding-inline: 3vw;
  font-size: 1.2rem;
}

.RemakeOrderPwdInputForm input{
  display:inline-block;
  width:50px;
  height:50px;
  margin-inline: 0.1rem;
  text-align:center;
}

.RemakeOrderPwdInputVerifyButton {
  cursor: pointer;
  width:fit-content;
  margin-inline: auto;
  margin-block: 1rem;
  padding-inline: 1rem;
  padding-block: 0.5rem;
  background-color: #6633ff;
  border: 2px solid #9747FF;
  border-radius: 10px;
  color:white
}

.RemakeOrderPwdInputVerifyWrongPwd {
  color: red;
  text-align: center;
  padding-block-start: 0.2rem;
}

/* After Password Accepted */

.ThankYouMessage {
  text-align: center;
  color: #6633ff;
  font-weight: 600;
  font-size: 3.2rem;
  padding: 2vh 17vw;
}

.ThankYouMessageSub {
  font-weight: 500;
  font-size: 2rem;
  margin-top: 30px;
  padding-inline: 10vw;
  text-align: center;
  color: #340066;
}

.RemakeOrderPreviewButtons {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  max-width: 1140px;
  margin-inline: auto;
}

.RemakeOrderPreviewButton {
  width: 30%;
  margin-inline: 1%;
  background-color: #6633ff;
  color: white;
  padding-block: 1vh;
  font-weight: 600;
  border: 1px solid #9747FF;
}

.RemakeOrderPreviewButton:hover,
.RemakeOrderPreviewModalDownloadButton:hover,
.RemakeOrderPreviewModalCloseButton:hover,
.RemakeOrderPreviewModalFooterCloseButton:hover,
.RemakeOrderPwdInputVerifyButton:hover {
  background-color: #dcb3ff;
  color: black;
  cursor: pointer;
  transition: all 0.3s;
}

/* PDFs Made with HTML */
.RemakeOrderPreviewModal {
  padding-block: 4vh;
}

.RemakeOrderPreviewModalHeaderTitle {
  font-size: 4vh;
  text-align: center;
  margin-inline: auto;
}

.RemakeOrderPreviewModalHeaderButtons {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-block: 2rem;
}

.RemakeOrderPreviewModalDownloadButton,
.RemakeOrderPreviewModalCloseButton,
.RemakeOrderPreviewModalFooterCloseButton {
  width: 30%;
  background-color: #6633ff;
  color: white;
  text-align: center;
  padding-block: 0.5rem;
  margin-inline: 3vw;
  font-weight: 600;
  border-radius: 15px;
  border: 1px solid #9747FF;
}

.RemakeOrderPreviewModalBody {
  padding-block: 4vh;
  margin-inline: 1vh;
}

.RemakeOrderPreviewModalPDFContainer {
  border: 1px solid #F2F2FF;
  border-radius: 10px;
  width: 73%;
  margin-inline: auto;
  margin-block-end: 2vh;
  overflow-x: scroll;
}

.RemakeOrderPreviewModalPDF {
  padding-inline: 3rem;
  padding-block: 2rem;
  width: 60rem;
}

.RemakeOrderPreviewModalPDFUpperRow {
  display: flex;
  justify-content:space-between;
  border-bottom: 2px solid #BFD4E4;
  padding-block-end: 2rem;
}

.RemakeOrderPreviewModalPDFUpperLeftSide {
  width: 50%;
  display: flex;
}

.RemakeOrderPreviewModalPDFUpperLeftLogo {
  width: 20rem;
  height: auto;
  margin-block: auto;
}

.RemakeOrderPreviewModalPDFUpperTitle {
  padding-inline-start: 2rem;
  font-family:Andale Mono, sans-serif, 'Arial Narrow';
  font-size: 1.7rem;
  font-weight: 500;
  line-height: 1.9rem;
  margin-block: auto;
}

.RemakeOrderPreviewModalPDFUpperRightTexts {
  text-align: right;
  line-height: 1.3rem;
  margin-block: auto;
  color: #A3AED0;
}

.RemakeOrderPreviewModalPDFUpperUpperRightOrderText {
  color: black;
  font-size: 1.3rem;
  margin-block-end: 0.5rem;
}

.RemakeOrderPreviewModalPDFUpperUpperRightText1 {
  font-size: 0.8rem;
}

.RemakeOrderPreviewModalPDFUpperUpperRightText2 {
  font-size: 0.9rem;
}

.RemakeOrderPreviewModalPDFPatientData {
  margin-block-start: 0.5rem;
  border-bottom: 2px solid #BFD4E4;
  padding-block-end: 1rem;
}

.RemakeOrderPreviewModalPDFPatientDataTitle,
.RemakeOrderPreviewModalPDFExamsDataTitle {
  font-family:Andale Mono, sans-serif, 'Arial Narrow';
  font-size: 1.2rem;
  font-weight: 600;
}

.RemakeOrderPreviewModalPDFPatientDataName {
  margin-block-start: 0.5rem;
}

.RemakeOrderPreviewModalPDFPatientDataSecondRow {
  display: flex;
  justify-content: space-between;
}

.RemakeOrderPreviewModalPDFExamsData {
  margin-block-start: 1rem;
  padding-block-end: 2rem;
  border-bottom: 2px solid #BFD4E4;
}

.RemakeOrderPreviewModalPDFExamsDataUpperFlexDiv {
  display: flex;
  justify-content: space-between;
  margin-block-end: 1rem;
}

.RemakeOrderPreviewModalPDFExamsDataLowerFlexDiv {
  display: flex;
  flex-flow: row wrap;
  margin-block-end: 0.5rem;
}

.RemakeOrderPreviewModalPDFExamsDataOneExam {
  width: 50%;
}

.RemakeOrderPreviewModalPDFExamsDataExamName {
  color: #330066;
  font-weight: 700;
  font-size: 0.9rem;
}

.RemakeOrderPreviewModalPDFExamsDataExamIcon {
  width: 1rem
}

.RemakeOrderPreviewModalPDFExamsDataExamPreparation {
  font-size: 0.9rem;
  color: #A3AED0;
  font-weight: 600;
}

.RemakeOrderPreviewModalPDFSignatureDiv{
  text-align: center;
  margin-block-start: 2rem;
  padding-block-end: 2rem;
  border-bottom: 2px solid #BFD4E4;
}

.RemakeOrderPreviewModalPDFSignatureImage {
  width: 20rem;
  margin-block-end: 1rem;
}

.RemakeOrderPreviewModalPDFDoctorName {
  font-weight: 700;
  line-height: 1rem;
}

.RemakeOrderPreviewModalPDFContactDiv {
  text-align: center;
  margin-block-start: 0.5rem;
  padding-block-end: 2rem;
  border-bottom: 2px solid #BFD4E4;
} 

.RemakeOrderPreviewModalPDFContactFlex {
  display: flex;
  justify-content: center;
}

.RemakeOrderPreviewModalPDFContactIcon {
  width: 1.5rem;
}

.RemakeOrderPreviewModalPDFContactRight,
.RemakeOrderPreviewModalPDFContactLeft {
  width: 15rem
}

.RemakeOrderPreviewModalPDFContactLowerText {
  margin-block: auto;
}

.RemakeOrderPreviewModalFooterCloseButton {
  margin-inline: auto;
}

.RemakeOrderPreviewModalFooter {
  padding-block-end: 4vh;
}

@media (max-width: 720px) {
  .ThankYouMessage {
    padding-inline: 5vw;
    font-size: 2.5rem;
  }
  
  .ThankYouMessageSub {
    padding-inline: 5vw;
    font-size: 1.5rem;
  }

  .RemakeOrderPreviewButton {
    width: 45%;
    margin-block: 1vh;
  }

  .RemakeOrderPreviewModalPDFContainer {
    width: 95%;
  }
}

@media (max-width: 520px) {
  .RemakeOrderPwdInput {
    width: 95%;
  }
  .RemakeOrderPwdInputForm input{
    display:inline-block;
    width:11%;
    height:50px;
    margin-inline: 0.1rem;
    text-align:center;
  }
}

@media (max-width: 420px) {
  .RemakeOrderCreator {
    margin-block: 3rem;
  }

  .RemakeOrderPreviewButton {
    width: 95%;
  }

  .RemakeOrderPreviewModalDownloadButton,
  .RemakeOrderPreviewModalCloseButton,
  .RemakeOrderPreviewModalFooterCloseButton {
    width: 80%;
    margin-block-end: 1vh;
  }
}