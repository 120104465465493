@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Montserrat";
}

.ScrollBackToTopOuter {
  position: fixed;
  bottom: 2rem;
  right: 3%;
  height: 54px;
  width: 54px;
  background: linear-gradient(0deg, rgba(207,249,255,1) 0%, rgba(251,255,255,1) 100%);
  border-radius: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.ScrollBackToTop {
  background-color: #CFF9FF;
  height: 50px;
  width: 50px;
  border-radius: 13px;
  color:#6633FF;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ScrollBackToTopUpArrow {
  height: 28px;
}

/* Font sizes */
:root {
  --buttonText: 16px;
  --generalText: 14px;
  --questionsText: 16px;
  --epigraphText: 18px;
  --pricesText: 18px;
  --bigpriceText: 40px;

  --topPageMargin: 145px;
}

h1 {
  font-size: 50px !important;

  @media (max-width: 992px) {
    font-size: 45px !important;
  }

  @media (max-width: 720px) {
    font-size: 40px !important;
  }

  @media (max-width: 400px) {
    font-size: 30px !important;
  }
}

h2 {
  text-transform: none !important;
  font-size: 36px !important;

  @media (max-width: 720px) {
    font-size: 25px !important;
  }
}

h3 {
  font-size: 30px !important;

  @media (max-width: 400px) {
    font-size: 20px !important;
  }
  
  @media (max-width: 720px) {
    font-size: 24px !important;
  }
}

h4 {
  font-size: 24px !important;
}

h5 {
  font-size: 20px !important;

  @media (max-width: 1200px) {
    font-size: 18px !important;
  }
}

/* Fonts definitions */

@font-face {
  font-family: 'Meltmino';
  font-weight: 200;
  src: url(./fonts/Meltmino-ExtraLight.ttf) format('truetype');
}

@font-face {
  font-family: 'Meltmino';
  font-weight: 300;
  src: url(./fonts/Meltmino-Light.otf) format('opentype');
}

@font-face {
  font-family: 'Meltmino';
  font-weight: 400;
  src: url(./fonts/Meltmino-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Meltmino';
  font-weight: 500;
  src: url(./fonts/Meltmino-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Meltmino';
  font-weight: 600;
  src: url(./fonts/Meltmino-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Meltmino';
  font-weight: 700;
  src: url(./fonts/Meltmino-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Meltmino';
  font-weight: 800;
  src: url(./fonts/Meltmino-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Meltmino';
  font-weight: 900;
  src: url(./fonts/Meltmino-Black.otf) format('opentype');
}


@font-face {
  font-family: 'VisbyCF';
  font-weight: 300;
  src: url(./fonts/VisbyCF-Light.otf) format('opentype');
}

@font-face {
  font-family: 'VisbyCF';
  font-weight: 400;
  src: url(./fonts/VisbyCF-Medium.otf) format('opentype');
}

@font-face {
  font-family: 'VisbyCF';
  font-weight: 500;
  src: url(./fonts/VisbyCF-DemiBold.otf) format('opentype');
}

@font-face {
  font-family: 'VisbyCF';
  font-weight: 600;
  src: url(./fonts/VisbyCF-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'VisbyCF';
  font-weight: 600;
  font-style: italic;
  src: url(./fonts/VisbyCF-BoldOblique.otf) format('opentype');
}

@font-face {
  font-family: 'VisbyCF';
  font-weight: 700;
  src: url(./fonts/VisbyCF-ExtraBold.otf) format('opentype');
}

@font-face {
  font-family: 'VisbyCF';
  font-weight: 800;
  src: url(./fonts/VisbyCF-Heavy.otf) format('opentype');
}