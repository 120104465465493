.FamilyFormDiv {
    color: #340066;
    font-weight: 600;
    max-width: 800px;
    margin-inline: auto;
}

.FamilyFormBlock {
    margin-block-end: 2rem;
}

.FamilyFormBlockHidden {
    display: none;
}

.FamilyFormTitle {
    font-size: 3.1vh;
    color: #6633FF;
    font-weight: 600;
    line-height: 3.3vh;
}

.FamilyFormButtons {
    display: grid;
    align-content: space-evenly;
    grid-template-columns: auto auto auto;
    gap: 1vw;
    padding-block: 4vh;
    user-select: none;
    text-align: center;
}

@media (max-width: 720px) {
    .FamilyFormButtons {
        grid-template-columns: auto auto;
    }
}

@media (max-width: 550px) {
    .FamilyFormButtons {
        grid-template-columns: auto;
    }

    .FamilyFormTitle {
        font-size: 2.6vh;
        line-height: 2.9vh;
    }
}

/*
@media (max-width: 768px) {

    .FamilyFormIndividualButton {
        font-size: 2vw;
        padding-bottom: 7vw;
        color: #340066;
    }

    .FamilyFormButtons {
        grid-template-columns: auto auto auto;
    }

    .FamilyFormTitle {
        font-size: 3vw;
        padding: 2vh 0vh;
        color: #6633FF;
        font-weight: 600;
    }

    .FamilyFormDiv {
        height: 70vh;
        align-items: start;
    }

    /* .FamilyRouteNextButton {
        grid-area: 25 / -14 / -1 / -1;
    }

    .FamilyRouteBackButton {
        grid-area: 25 / 1 / -2 / -15;
    } 
}

@media (max-width: 425px) {
      .FamilyFormIndividualButton {
        font-size: 3vw;
        padding: 8vw 3vw 12vw 3vw;
    }

    .FamilyFormTitle {
        font-size: 5vw;
    }
    .FamilyFormButtons {
        grid-template-columns: auto auto auto;
    }

}*/