.DataRouteFormDiv {
    color: rgb(52, 0, 102);
    font-weight: 600;
    padding-block: 4vh;
    max-width: 800px;
    margin-inline: auto;
}

.DoBTile {
    background-color: #FBFFFF;
    border: #cff9ff solid 1px;
    padding-inline: 2vh;
    padding-block: 1vh;
    font-weight: 500;

    &:focus {
        color: black;
    }
}

.InputTile {
    background-color: #FBFFFF;
    padding-inline: 0.5vw;
    padding-block: 2vh;
    margin: 0.5vw 0.5vw 0.5vw 0.5vw;
    width: 100%;
    height: 2.2vw;
    &:focus {
        outline: #94d7f392 solid 2px;
    }
}

.DataRouteNameDiv,
.DataRouteRuteDiv,
.DataRouteGenderDiv,
.DataRouteDateDiv,
.DataRouteWeightDiv,
.DataRouteHeightDiv {
    margin-block-end: 3vh;
}

.DataRouteGenderDateFlex,
.DataRouteWeightHeightFlex {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
}

.DataRouteGenderDiv,
.DataRouteDateDiv,
.DataRouteWeightDiv,
.DataRouteHeightDiv {
    width: 48%;
}

.DataRouteGenderDiv,
.DataRouteDateDiv {
    padding-inline-end: 2%;
}

.NameAlert,
.RutAlert,
.GenderAlert,
.WeightAlert,
.HeightAlert {
    font-size: 2vh;
}

.GenderTitle {
    margin-bottom: 0.5vh;
}

.GenderTilesFlexed,
.DateTilesFlexed {
    display: flex;
}

.GenderMale,
.GenderFemale {
    width:fit-content;
    text-align: center;
}

.GenderMale {
    margin-inline-end: 1vw;
}

.DoBTitle {
    margin-bottom: 0.5vh;
}

.DoBMonth,
.DoBYear {
    margin-inline-start: 1vh;
}

.DataRouteLastTime {
    display: flex;
}

.DataRouteLastTimeDiv {
    margin-block-end: 2rem;
}

.DataRouteLastTimeText {
    margin-inline-end: 2rem;
}

.DataRouteLastTimeEncourageMessage {
    text-align: center;
    font-weight: 500;
    margin-block-start: 1rem;
}

@media (max-width:720px) {
    .DataRouteGenderDiv,
    .DataRouteDateDiv,
    .DataRouteWeightDiv,
    .DataRouteHeightDiv {
        width: 100%;
    }

    .DoBTile {
        padding-inline: 0.5vw;
        padding-block: 1vh;
    }
}