.GenderTile {
    border: #cff9ff solid 1px;
    padding: 2vh 4vh;
    background-color: #FBFFFF;
    font-weight: 500;
    cursor: pointer;
    &:hover {
        box-shadow: rgba(52, 0, 102, 0.24) 0px 3px 8px;
    }

    &:active {
        box-shadow: rgba(52, 0, 102, 0.16) 0px 1px 4px;
    }
}

.GenderTileActive {
    cursor: pointer;
    border: #6633FF solid 1px;
    padding: 2vh 4vh;
    background-color: #CCCCFF;
    font-weight: 500;

    &:hover {
        box-shadow: rgba(52, 0, 102, 0.24) 0px 3px 8px;
    }

    &:active {
        box-shadow: rgba(52, 0, 102, 0.16) 0px 1px 4px;
    }
}

.GenderTileDisabled {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    font-size: 3vh;
    border: #cff9ff solid 1px;
    height: 100%;
    background-color: #FBFFFF;
    font-weight: 500;
    cursor: not-allowed;
    opacity: 0.8;
}


.GenderTileActiveDisabled {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    height: 100%;
    cursor: not-allowed;
    border: #6633FF solid 1px;
    font-size: 3vh;
    background-color: #CCCCFF;
    font-weight: 500;
    opacity: 0.8;
}

.GeneralPurpleButtonFlex {
    display: flex;
    justify-content: center;
    text-align: center;
    background-color: #6633FF;
    border: 2px solid #CCCCFF;
    border-radius: 12px;
    padding-block: 20px;
    color: white;
    padding-inline: 30px;
    width: fit-content;
}

.GeneralWhiteButtonFlex {
    display: flex;
    justify-content: center;
    text-align: center;
    background-color: #FBFFFF;
    border: 2px solid #6633FF;
    border-radius: 12px;
    padding-block: 20px;
    color: #6633FF;
    padding-inline: 30px;
    width: fit-content;
}

.GeneralPurpleButtonFlex:hover {
    cursor: pointer;
    background-color: #EFEFFF;
    color: #6633FF;
    transition: all 0.3s;
    border: 2px solid #6633FF;
}

.GeneralWhiteButtonFlex:hover {
    cursor: pointer;
    background-color: #EFEFFF;
    color: #6633ff;
    transition: all 0.3s;
    border: 2px solid #6633FF;
}

.GeneralPurpleButtonFlex:hover:active,
.GeneralWhiteButtonFlex:hover:active {
    background-color: #CCCCFF;
}

.GeneralPurpleButtonText {
    margin-block: auto;
    font-weight: 500;
    font-size: var(--buttonText);
    margin-inline-end: 12px;
}

.GeneralWhiteButtonText {
    margin-block: auto;
    font-weight: 500;
    font-size: var(--buttonText);
    margin-inline-start: 12px;
}

.GeneralPurpleButtonIcon {
    width: 20px;
    height: 20px;
    margin-block: auto;
}

@media (max-width: 440px) {
    .GenderTile,
    .GenderTileActive {
        padding: 1vh 2vh;
        font-size: 0.8rem;
    }
}

/*
@media (max-width:1024px) {
    .GenderTile, .GenderTileActive {
        padding: 1.5vw 2vw;
    }
}

@media (max-width:768px) {
    .GenderTile, .GenderTileActive {
    padding: 2vw 1vw;
    font-size: 1.6vw;
    }
}

@media (max-width:425px) {
    .GenderTile {
        border: #cff9ff solid 1px;
        font-size: inherit;
        padding: 0.8rem;
        background-color: #FBFFFF;
        font-weight: 500;
        cursor: pointer;
        height: 6vh;
        &:hover {
            box-shadow: rgba(52, 0, 102, 0.24) 0px 3px 8px;
        }
    
        &:active {
            box-shadow: rgba(52, 0, 102, 0.16) 0px 1px 4px;
        }
    }
    
    .GenderTileActive {
        height: 6vh;
        cursor: pointer;
        border: #6633FF solid 1px;
        font-size: inherit;
        padding: 0.8rem;
        background-color: #CCCCFF;
        font-weight: 500;
    
        &:hover {
            box-shadow: rgba(52, 0, 102, 0.24) 0px 3px 8px;
        }
    
        &:active {
            box-shadow: rgba(52, 0, 102, 0.16) 0px 1px 4px;
        }
    }
    
    .GenderTileDisabled {
        -webkit-user-select: none; 
        -ms-user-select: none; 
        user-select: none; 
        font-size: inherit;
        border: #cff9ff solid 1px;
        height: 100%;
        padding: 0.8rem;
        background-color: #FBFFFF;
        font-weight: 500;
        cursor: not-allowed;
        opacity: 0.8;
    }
    
    
    .GenderTileActiveDisabled {
        -webkit-user-select: none;
        -ms-user-select: none; 
        user-select: none;
        height: 100%;
        cursor: not-allowed;
        border: #6633FF solid 1px;
        font-size: inherit;
        padding: 0.8rem;
        background-color: #CCCCFF;
        font-weight: 500;
        opacity: 0.8;
    }
} */