.LandingPage::-webkit-scrollbar {
  display: none;
}

.LandingPage {
  align-items: top;
  overflow-y: scroll;
  overflow-x: hidden;
  color: #330066;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  margin-block-start: var(--topPageMargin);
}

.NavBarLanding {
  z-index: 2;
  position: sticky;
  top: 0;
}

.LandingSliderImageContain {
  padding-inline: 2%;
}

.LandingSliderImageCarousel {
  border-radius: 20px;
  overflow: hidden;
  margin-inline: auto;
  max-width: 1300px;
  touch-action: pan-y;
}

.LandingSliderImage {
  margin-inline: auto;
  max-width: 1300px;
  padding-block-end: 2rem;
  text-align: left;
  height: 100%;
}

.LandingSliderUpperIndicator {
  background-color: #CFF9FF;
  height: 8px;
  width: 25%;
  margin-inline-start: 0.5rem;
  display: inline-flex;
}

.LandingSliderUpperIndicatorActive {
  background-color: #6633FF;
}

.LandingSliderTexts {
  height: 100%;
  max-width: 40vw;
  color: white;
  padding-inline-start: 3vw;
  padding-block: 2rem;
  margin-block: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.LandingSliderTextsWider {
  max-width: 100%;
  width: 50%
}

.LandingSliderTitle {
  font-weight: 700;
  padding-block-end: 1rem;
  font-family: 'Meltmino', sans-serif;
}

.LandingSliderText {
  font-size: var(--generalText);
  padding-block-end: 2rem;
}

.LandingSliderButtonFlex {
  display: flex;
}

.HeartLandingButton {
  color: #6633FF;
  background-color: white;
  border: #9747FF solid 2px;
  text-align: center;
  border-radius: 10px;
  font-size: var(--buttonText);
  padding-block: 1.2rem;
  padding-inline: 0.3rem;
  margin-inline-end: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.HeartLandingButtonRight {
  color: white;
  background-color: #6633ff;
  border: #9747FF solid 2px;
  border-radius: 10px;
  font-size: var(--buttonText);
  padding-block: 1.3rem;
  padding-inline: 0.3rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.LandingSlider2 {
  border: 1px solid #CFF9FF;
  background-color: #FBFFFF;
  color: #6633FF;
  margin-block-start: 2rem;
  margin-inline: auto;
  max-width: 1140px;
  overflow: hidden;
  height: 95%;
}

.LandingSlider2Flex {
  display: flex;
  justify-content: space-between;
  padding-inline: 5%;
  padding-block: 1rem;
  height: 100%;
}

.LandingSlider2LeftSide {
  text-align: left;
  max-width: 30rem;
  margin-block: auto;

}

.LandingSlider2UpTitle {
  font-size: var(--epigraphText);
  font-weight: 600;
  color: #6633FF;
  text-align: left;
}

.LandingSlider2Title {
  font-weight: 700;
  padding-inline-end: 2%;
  margin-block: 1rem;
  color: #6633FF;
  text-align: left;
  font-family: 'Meltmino', sans-serif;
}

.LandingSlider2Subtitle {
  font-size: var(--generalText);
  padding-block-end: 1rem;
  text-align: left;
  color: #6633FF;
}

.LandingSlider2RightSide {
  margin-block: auto;
  width: 50%;
  height: fit-content;
}

.LandingSlider2RightSideImage {
  width: 20%;
  height: auto;
  margin-block: auto;
}

.LandingSlider2RightSideButtonFlex {
  display: flex;
  justify-content: space-between;
  border: 1px solid #CCCCFF;
  background-color: #6633FF;
  border-radius: 10px;
  width: 17rem;
}

.LandingSlider2RightSideButtonLeft {
  padding-inline: 2%;
  text-align: center;
  margin-block: auto;
  margin-inline: auto;
  font-weight: 500;
  font-size: var(--buttonText);
}

.LandingSlider2RightSideButtonRight {
  border-left: 1px solid #CCCCFF;
  padding-inline: 1rem;
  padding-block: 1.7rem;
  font-size: var(--buttonText);
}

.LandingSlider2RightSideButtonRightImage {
  height: 30px;
  width: 30px;
  max-width: 30px;
}

.LandingSlider2BottomImage {
  height: 50%;
  width: 100%;
}

/* Consigue tu órden médica */
.LandingMedicOrderSection {
  max-width: 1140px;
  margin-inline: auto;
  margin-block-start: 5rem;
  margin-block-end: 4rem;
  width: 100%;
  align-items: center;
  padding-inline: 10vw;
}

.LandingMedicOrderSectionUpTitle,
.LandingDidYouKnowUpTitle,
.LandingWeAreYMCUpTitle,
.LandingValueProposalUpTitle,
.LandingSelfCareUpTitle,
.LandingYouCanTrustUpTitle {
  background-color: #CFF9FF;
  width:fit-content;
  margin-inline: auto;
  padding-block: 0.7rem;
  padding-inline: 1rem;
  border-radius: 10px;
  letter-spacing: 1px;
}

.LandingMedicOrderSectionTitle {
  text-align: center;
  font-size: 2.4rem;
  letter-spacing: 1px;
  font-weight: 700;
  margin-block: 1rem;
  font-family: 'Meltmino', sans-serif;
  color: #330066;
}

.LandingMedicOrderSectionSubtitle {
  text-align: center;
  font-size: var(--generalText);
  color: #330066;
}

.LandingMedicOrderSectionPackBlockFlex {
  display: flex;
  margin-block: 1rem;
  max-width: 1140px;
  margin-inline: auto;
}

.LandingMedicOrderSectionPackBlockLeft,
.LandingMedicOrderSectionPackBlockRight {
  width: 50%;
  margin-inline: 1%;
  background-color: white;
  border: 1px solid #F2F2FF;
  border-radius: 5px;
  padding-block: 1rem;
  padding-inline: 2%;
  margin-block-end: 1rem;
}

.LandingMedicOrderSectionPackBlockImageDiv,
.LandingMedicOrderSectionPackBlockTexts {
  width: 100%;
}

.LandingMedicOrderSectionPackBlockLeftImage,
.LandingMedicOrderSectionPackBlockRightImage {
  width: 8rem;
  height: auto;
}

.LandingMedicOrderSectionPackBlockLeftTitle,
.LandingMedicOrderSectionPackBlockRightTitle {
  letter-spacing: 1px;
  color: #330066;
  font-weight: 500;
  padding-block: 0.5rem;
  font-family: 'Meltmino', sans-serif;
}

.LandingMedicOrderSectionPackBlockLeftSubtitle,
.LandingMedicOrderSectionPackBlockRightSubtitle {
  font-size: var(--generalText);
  color: #330066;
}

.LandingMedicOrderSectionPackBlockLeftButton,
.LandingMedicOrderSectionPackBlockRightButton {
  margin-block-start: 1rem;
  padding-block: 0.8rem;
  padding-inline: 5%;
  color: white;
  background-color: #6633FF;
  border: 2px solid #CCCCFF;
  border-radius: 10px;
  width: fit-content;
  font-size: var(--buttonText);
  font-weight: 500;
  text-align: center;
}

/* Make it easy section */
.LandingMedicOrderMakeItEasy {
  background-color: #CFF9FF;
  border: 1px solid #10DEFF;
  padding-block: 3rem;
  text-align: center;
  color: #6633FF;
}

.LandingMedicOrderMakeItEasyinnerDiv {
  max-width: 1140px;
  margin-inline: auto;
}

.LandingMedicOrderMakeItEasyTitle {
  font-weight: 700;
  font-size: 2.5rem;
  color: #6633FF;
  font-family: 'Meltmino', sans-serif;
  padding-inline: 1%;
}

.LandingMedicOrderMakeItEasySubtitle {
  color: #6633FF;
  letter-spacing: 1px;
  font-weight: 500;
  padding-block: 1rem;
  font-size: 0.8rem;
  max-width: 26rem;
  padding-inline: 1vw;
  margin-inline: auto;
}

.LandingMedicOrderMakeItEasyInstructionsFlex {
  display: flex;
  justify-content: space-around;
  padding-inline: 5vw;
  margin-block-start: 1.5rem;
  margin-block-end: 1rem;
}

.LandingMedicOrderMakeItEasyInstructionsBlock {
  width: 100%;
  padding-inline: 1vw;
}

.LandingMedicOrderMakeItEasyInstructionsTitle {
  font-size: 1.2rem;
  font-weight: 600;
  padding-block-start: 1rem;
  font-family: 'Meltmino', sans-serif;
  max-width: 200px;
  margin-inline: auto;
}

.LandingMedicOrderMakeItEasyInstructionsSubtitle {
  font-size: var(--generalText);
  padding-inline: 3vw;
  padding-block-start: 1rem;
}

.LandingMedicOrderMakeItEasyInstructionsImage {
  width: auto;
  height: 7rem;
}

.LandingMedicOrderMakeItEasyFoot {
  text-decoration: underline;
  font-size: 0.8rem;
  cursor: pointer;
}

/* Gender Tabs section*/

.LandingMedicOrderSectionTabContentFlex {
  display: flex;
  margin-block-start: 2rem;
  margin-block-end: 3rem;
  overflow: hidden;
  max-width: 1140px;
  margin-inline: auto;
  padding-inline: 2%;
}

.LandingMedicOrderSectionTabContentBlockFemale {
  height: 100%;
  padding-block: 4rem;
  padding-inline: 5%;
  width: 50%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  overflow: hidden;
}

.LandingMedicOrderSectionTabContentBlockMale {
  height: 100%;
  padding-block: 4rem;
  padding-inline: 5%;
  width: 50%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
}

.LandingMedicOrderSectionTabContentBlockText {
  color: white;
  font-size: 3.5vmin;
  font-family: 'Meltmino', sans-serif;
  font-weight: 700;
}

.LandingMedicOrderSectionTabContentBlockButton {
  background-color: #FBFFFF;
  color: #6633FF;
  margin-block-start: 1rem;
  padding-block: 0.8rem;
  padding-inline: 5%;
  border: 2px solid #6633FF;
  border-radius: 10px;
  width: fit-content;
  font-size: 0.9rem;
  font-weight: 500;
  text-align: center;
}

.LandingMedicOrderSectionTabContentRegister {
  font-size: 0.9rem;
  text-align: center;
  padding-inline: 1%;
}

/* Did You Know section */
.LandingDidYouKnowDiv {
  padding-block-start: 5rem;
  padding-block-end: 5rem;
  text-align: center;
}

.LandingDidYouKnowInnerDiv {
  max-width: 1140px;
  margin-inline: auto;
}

.LandingDidYouKnowBlockFlex {
  display: flex;
  justify-content: center;
}

.LandingDidYouKnowCenterPartInactive {
  opacity: 0;
  transition: all 1s ease;
  height: 0px;
  position:fixed;
}

.LandingDidYouKnowCenterPart {
  width: 55%;
  margin-block-start: 1rem;
  transition: all 1s ease;
}

.LandingDidYouKnowArrow {
  margin-block: auto;
  color: white;
  text-align: center;
  font-size: 2rem;
  padding-inline: 5%;
  padding-block: 5rem;
  cursor: pointer;
}

.LandingDidYouKnowTitle {
  text-align: center;
  letter-spacing: 1px;
  font-weight: 700;
  margin-block: 1rem;
  margin-inline: auto;
  color:white;
  font-family: 'Meltmino', sans-serif;
  text-shadow: 0px 3px 3px rgb(143, 143, 143);
}

.LandingDidYouKnowSubtitle {
  margin-block-start: 2rem;
  margin-block-end: 1rem;
  font-size: var(--generalText);
  color: #330066;
}

.LandingDidYouKnowFlexDiv,
.LandingWeAreYMCFlexDiv {
  display: flex;
  justify-content: center;
}

.LandingDidYouKnowFlexedButton,
.LandingWeAreYMCFlexedButtonLeft,
.LandingWeAreYMCFlexedButtonRight,
.LandingSelfCareButtonLeft,
.LandingSelfCareButtonRight  {
  width:fit-content;
  margin-inline: 0.5rem;
  margin-block: 1rem;
  padding-block: 1rem;
  padding-inline: 3rem;
  border-radius: 10px;
  font-size: var(--buttonText);
}

.LandingDidYouKnowFlexedButton,
.LandingWeAreYMCFlexedButtonLeft,
.LandingSelfCareButtonLeft {
  background-color: #6633FF;
  color: white;
  border: 1px solid #9747FF;
}

.LandingDidYouKnowFlexedButton {
  margin-inline: auto;
  cursor: pointer;
  font-style: italic;
}

.LandingWeAreYMCFlexedButtonRight,
.LandingSelfCareButtonRight {
  background-color: white;
  border: 1px solid #9747FF;
  color: #6633FF;
}

/* You Can Trust section */
.LandingYouCanTrustDiv {
  text-align: center;
  padding-block-start: 4rem;
  padding-block-end: 6rem;
  position: relative;
}

.LandingYouCanTrustInnerDiv {
  max-width: 1140px;
  margin-inline: auto;
}

.LandingYouCanTrustTitle {
  color: #6633FF;
  font-weight: 600;
  margin-block: 1rem;
  font-family: 'Meltmino', sans-serif;
}

.LandingYouCanTrustFlexDiv {
  display: flex;
  flex-flow: row wrap;
  max-width: 970px;
  justify-content: center;
  margin-inline: auto;
}

.LandingYouCanTrustBlock {
  text-align: left;
  border: 1px solid #BFD4E4;
  border-radius: 3px;
  background-color: white;
  width: 30%;
  padding-inline: 1rem;
  padding-block: 1.2rem;
  margin-inline: 0.5rem;
  margin-block-end: 1rem;
}

.LandingYouCanTrustBlockImage {
  height: 2.5rem;
  width: auto;
}

.LandingYouCanTrustBlockTitle {
  letter-spacing: 1px;
  font-size: 1rem;
  font-weight: 600;
  margin-block: 0.7rem;
  color: #6633FF;
}

.LandingYouCanTrustBlockSubtitle {
  font-size: var(--generalText);
  color: #330066;
}

.rectangle {
  background-color: #F2F2FF;
  z-index: -1;
  position: absolute;
  height: 20rem;
  bottom: 4rem;
  width: 100%;
}

/* Somos YMC */
.LandingWeAreYMCDiv {
  padding-inline: 1%;
}

.LandingWeAreYMCInnerDiv {
  max-width: 1140px;
  padding-inline: 10%;
  margin-inline: auto;
  display: flex;
}

.LandingWeAreYMCLeftSide,
.LandingWeAreYMCRightSide {
  width: 50%;
  margin-block: auto;
}

.LandingWeAreYMCLeftSide {
  padding-block: 2rem;
}

.LandingWeAreYMCTitle {
  color: #330066;
  font-weight: 600;
  margin-block: 1rem;
  font-family: 'Meltmino', sans-serif;
}

.LandingWeAreYMCSubtitle {
  font-size: var(--generalText);
  font-weight: 500;
  line-height: 1.2rem;
  letter-spacing: 1px;
  color: #330066;
}

.LandingWeAreYMCRightSideImage {
  width: 100%;
  padding-inline-start: 5%;
  height: auto;
}

.LandingWeAreYMCFlexedButtonLeft,
.LandingWeAreYMCFlexedButtonRight,
.LandingSelfCareButtonLeft,
.LandingSelfCareButtonRight {
  margin-inline: 0px;
  margin-inline-end: 2vh;
  width: 50%;
  text-align: center;
  padding-inline: 1vw;
}


/* Propuesta de Valor */
.LandingValueProposalDiv {
  padding-block: 6rem;
  text-align: center;
}

.LandingValueProposalTitle {
  text-align: center;
  color: #330066;
  font-size: 2.3rem;
  letter-spacing: 0px;
  font-weight: 600;
  margin-block-start: 1rem;
  font-family: monospace, sans-serif;
}

.LandingValueProposalFlexedBoxes {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-block: 2rem;
  max-width: 970px;
  margin-inline: auto;
}

.LandingValueProposalWhiteBox {
  width: 24%;
  background-color: rgba(255,255,255, 0.8);
  border: 1px solid #BFD4E4;
  border-radius: 10px;
  padding-block: 1rem;
  padding-inline: 1vw;
  margin-inline: 0.5%;
  margin-block-start: 0.5rem;
}

.LandingValueProposalInnerBox {
  width: 80%;
  margin-inline: auto;
  height: 6.5rem;
  background-color: #D9D9D9;
}

.LandingValueProposalImage {
  width: 10rem;
  height: auto
}

.LandingValueProposalWhiteBoxTitle {
  font-size: 1rem;
  margin-inline: auto;
  letter-spacing: 1px;
  margin-block: 0.6rem;
}

.LandingValueProposalWhiteBoxSubtitle {
  font-size: 0.75rem;
}

.LandingValueProposalWhiteBoxTextOption {
  color: #480090;
  font-weight: 500;
  cursor: pointer;
}


/* Planificación de autocuidado */
.LandingSelfCareDiv {
  margin-inline: 4vw;
}

.LandingSelfCareRightside {
  width: 50%;
  margin-inline-start: 50%;
  margin-block: auto;
  padding-block: 1.5rem;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  padding-inline: 0.5vh;
}

.LandingSelfCareUpTitle {
  width:fit-content;
  margin-inline: 0px;
}

.LandingSelfCareTitle {
  text-align: left;
  font-size: 2.5rem;
  letter-spacing: 2px;
  font-weight: 400;
  margin-block: 1.5rem;
}

.LandingSelfCareSubtitle {
  padding-inline-end: 1rem;
  line-height: 2rem;
}

.LandingSelfCareFlexedButtons {
  display: flex;
  margin-block-start: 2.5rem;
}

.LandingSelfCareButtonLeft,
.LandingSelfCareButtonRight {
  margin-inline: 0px;
  margin-inline-end: 1rem;
  margin-block-end: 0px;
  width: 50%;
  text-align: center;
}

.plus {
  background-color: #cff9ff;
  height: 40rem;
  position: absolute;
  top: 120vh;
  right: 2vh;
  width: 15rem;
  z-index: -1;

  &:after {
    background-color: #cff9ff;
    content: "";
    height: 15rem;
    left: -14rem;
    position: absolute;
    top: 28vh;
    width: 29.8rem;
  }
}

.HeartLandingButton:hover,
.LandingDidYouKnowFlexedButtonRight:hover,
.LandingWeAreYMCFlexedButtonRight:hover,
.LandingSelfCareButtonRight:hover {
  background-color: #EFEFFF;
  cursor: pointer;
  transition: all 0.3s;
  color: #6633ff;
}

.HeartLandingButtonRight:hover,
.LandingMedicOrderSectionSeeExamsButton:hover,
.LandingMedicOrderSectionTabContentWhiteBoxMoreInfoButton:hover,
.LandingMedicOrderSectionTabContentHorizontalBlockButton:hover,
.LandingDidYouKnowFlexedButtonLeft:hover,
.LandingWeAreYMCFlexedButtonLeft:hover,
.LandingSelfCareButtonLeft:hover,
.LandingDidYouKnowFlexedButton:hover,
.LandingMedicOrderSectionPackBlockLeftButton:hover,
.LandingMedicOrderSectionPackBlockRightButton:hover,
.LandingMedicOrderSectionTabContentBlockButton:hover {
  background-color: #EFEFFF;
  color: #6633ff;
  cursor: pointer;
  transition: all 0.3s;
  border: 2px solid #6633FF;
}

.LandingSlider2RightSideButtonFlex:hover {
  background-color: #CCCCFF;
  color: #6633ff;
  transition: all 0.3s;
  border: 1px solid #6633FF;
  cursor: pointer;
}

.LandingMedicOrderSectionSingleTab,
.ScrollBackToTop {
  cursor: pointer;
}

.ScrollBackToTopHidden {
  display: none;
}


@media (max-width: 720px) {

  .LandingSliderTexts {
    width: 100%;
    max-width: 100%;
    padding-inline: 3vw;
    padding-block: 2.5rem;
    align-items: center;
  }

  .LandingSlider2Flex {
    flex-flow: row wrap;
    height: 70%;
  }

  .LandingSlider2RightSide {
    display: none;
  }

  .LandingSlider2BottomImage {
    height: 30%;
  }

  .LandingSlider2LeftSide,
  .LandingSlider2RightSide {
    width: 100%;
    text-align: center;
    margin-inline: auto;
  }

  .LandingSlider2UpTitle {
    font-size: 1.5rem;
  }

  .LandingSlider2Title {
    padding-inline: 2%;
    margin-block-start: 2rem;
  }

  .LandingSlider2RightSideButtonFlex {
    margin-inline: auto;
  }

  .LandingSlider2RightSideButtonRight {
    padding-block: 1rem;
  }

  .LandingMedicOrderSection {
    padding-inline: 5vw;
    margin-block-start: 4rem;
  }

  .LandingMedicOrderSectionTabContentFlex {
    flex-flow: row wrap;
  }

  .LandingDidYouKnowTitle {
    margin-inline: 3vw;
  }

  .LandingDidYouKnowSubtitle {
    margin-inline: 5vw;
  }

  .LandingDidYouKnowCenterPart {
    width: 80%;
  }

  .LandingDidYouKnowArrow {
    padding-inline: 2%;
  }

  .LandingWeAreYMCTitle {
    line-height: 2.5rem;
  }

  .LandingValueProposalFlexedBoxes {
    margin-block: 5vh;
  }

  .LandingValueProposalWhiteBox {
    width: 45%;
    margin-block: 2vh;
    margin-inline: 1vw;
  }

  .LandingSelfCareDiv {
    margin-inline: 1vw;
    background-position: 'left'
  }

  .LandingSelfCareRightside {
    width: 60%;
    margin-inline-start: 40%;
  }

  .LandingSelfCareTitle {
    line-height: 2.5rem;
    margin-block: 1rem;
  }

  .LandingSelfCareFlexedButtons {
    margin-block-start: 1rem;
  }

  .LandingSelfCareSubtitle {
    font-size: 0.9rem;
  }

  .LandingMedicOrderSectionTabContentBlockText {
    font-size: 1.5rem;
  }

  .LandingMedicOrderSectionPackBlockFlex {
    flex-flow: row wrap;
  }
  
  .LandingMedicOrderSectionPackBlockLeft,
  .LandingMedicOrderSectionPackBlockRight {
    width: 100%;
  }

  .LandingMedicOrderSectionPackBlockLeft,
  .LandingMedicOrderSectionPackBlockRight {
    display: flex;
  }

  .LandingMedicOrderSectionPackBlockImageDiv {
    width: 10rem;
  }

  .LandingMedicOrderSectionPackBlockTexts {
    width: auto;
  }

  .LandingWeAreYMCInnerDiv {
    padding-inline: 5%;
  }

  .LandingWeAreYMCLeftSide {
    width: 70%;
  }

  .LandingWeAreYMCRightSide {
    width: 30%;
  }
  
}

@media (max-width: 620px) {
  .LandingYouCanTrustBlock {
    width: 45%;
  }
}

@media (max-width: 520px) {

  .LandingMedicOrderMakeItEasyInstructionsFlex {
    flex-flow: row wrap;
    margin-block-start: 0rem;
  }

  .LandingMedicOrderMakeItEasyInstructionsBlock {
    margin-block-start: 1.5rem;
  }

  .LandingMedicOrderSectionTitle,
  .LandingValueProposalTitle {
    line-height: 2.5rem;
  }
  .LandingSelfCareRightside {
    width: 100%;
    margin-block: auto;
    text-align: center;
    align-items: center;
    margin-inline: auto;
  }

  .LandingSelfCareUpTitle {
    margin-inline: auto;
    margin-block-start: 2vh;
  }

  .LandingSelfCareTitle {
    text-align: center;
  }

  .LandingSelfCareSubtitle {
    padding: 0px;
    font-weight: 600;
  }

  .LandingSelfCareButtonRight,
  .LandingSelfCareButtonLeft {
    margin-inline: 1vh;
  }

  .LandingWeAreYMCLeftSide {
    width: 100%;
  }

  .LandingWeAreYMCRightSide {
    display: none;
  }
}
/* For iPhone screen or smaller */
@media (max-width: 440px) {
  .LandingSliderTexts{
    padding-block: 2rem;
  }
  .LandingSliderImage{
    margin-top: 10px;
  }
  .LandingSliderTitle, .LandingSlider2Title{
    padding-inline: 0%;
  }
  
  .LandingSlider2UpTitle {
    font-size: 1.2rem;
  }

  .LandingValueProposalWhiteBox {
    width: 100%;
  }

  .LandingSelfCareGrayBox {
    width: 100%;
  }

  .LandingWeAreYMCFlexedButtonLeft,
  .LandingWeAreYMCFlexedButtonRight {
    margin-inline: 1vh;
  }

  .LandingDidYouKnowFlexedButtonLeft,
  .LandingDidYouKnowFlexedButtonRight {
    padding-inline: 10%;
  }

  .LandingValueProposalFlexedBoxes {
    margin-block: 4vh;
  }

  .LandingYouCanTrustTitle {
    font-size: 1.8rem;
  }

  .LandingYouCanTrustBlock {
    width: 95%;
  }

  .LandingMedicOrderSectionTabContentBlockFemale,
  .LandingMedicOrderSectionTabContentBlockMale {
    padding-block: 2rem;
  }

  .LandingMedicOrderSectionTabContentBlockText {
    font-size: 1.2rem;
  }

  .LandingWeAreYMCFlexDiv {
    display: block;
  }

  .LandingWeAreYMCFlexedButtonLeft,
  .LandingWeAreYMCFlexedButtonRight {
    width: 100%;
    margin-inline: 0%;
  }
}

@media (max-width: 350px) {

  .LandingDidYouKnowSubtitle {
    font-size: 0.7rem;
    margin-inline: 1vw;
  }

  .LandingDidYouKnowFlexedButton {
    font-size: 0.7rem;
  }

  .HeartLandingButton,
  .HeartLandingButtonRight {
    font-size: 0.9rem;
    padding-inline: 0.2rem;
  }

  .LandingMedicOrderSectionPackBlockLeftImage,
  .LandingMedicOrderSectionPackBlockRightImage {
    width: 5rem;
  }

  .LandingMedicOrderSectionPackBlockImageDiv {
    width: 7rem;
  }

  .LandingSlider2RightSideButtonRight,
  .LandingSlider2RightSideButtonLeft {
    padding-inline: 2%;
  }

  .LandingSlider2RightSideButtonFlex {
    width: 100%;
  }
}
