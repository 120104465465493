.ExamRecommendationRouteContent {
    margin-block-start: var(--topPageMargin);
}

.ExamRecommendationFormInnerDiv1,
.ExamRecommendationFormDivEmpty {
    align-items: baseline;
    color: #340066;
    border: 1px solid #F2F2FF;
    margin-block-end: 1rem;
}

.ExamRecommendationFormDivEmpty {
    text-align: center;
    padding-block: 2rem;
    border-radius: 15px;
}

.ExamRecommendationFormEmpty {
    padding-inline: 2%;
    font-size: 2rem;
    padding-block: 1rem;
}

.ExamRecommendationFormEmptyInfo {
    padding-block-start: 1rem;
    font-size: 1.2rem;
    padding-inline: 2%;
}

.ExamRecommendationFormEmptyButton {
    background-color: #6633FF;
    color: white;
    border: 1px solid #CFF9FF;
    border-radius: 15px;
    text-align: center;
    width: fit-content;
    padding-block: 0.5rem;
    padding-inline: 1rem;
    margin-inline: auto;
    margin-block: 0.5rem;
    font-size: 1.1rem;
    font-weight: 600;
}

.ExamRecommendationFormEmptyButton:hover {
    background-color: #dcb3ff;
    color: black;
    cursor: pointer;
    transition: all 0.3s;
}

.ExamRecommendationFormTitle,
.ExamRecommendationFormTitleFlex {
    background-color: #F2F2FF;
    padding-inline: 1vh;
    padding-block: 1vh;
    font-weight: 600;
}

.ExamRecommendationFormInnerBox {
    margin-inline: 0.5rem;
    margin-block: 1rem;
    padding-block: 0.5rem;
    padding-inline-start: 1vh;
    border-left: 2px solid #BFD4E4;
}

.ExamRecommendationFormInnerBoxUpperRow,
.ExamRecommendationFormInnerBoxUpperRowIcons,
.ExamRecommendationFormInnerBoxLowerLinesFlex,
.ExamRecommendationFormInnerBoxShowDetails,
.ExamRecommendationFormInnerBoxExamLinesFlex,
.ExamRecommendationFormInnerBoxExamLineExamNameFlex,
.ExamRecommendationFormInnerBoxRiskLines,
.ExamRecommendationFormInnerBoxLowerLinesPageDots,
.ExamRecommendationFormTitleRightSide,
.ExamRecommendationFormInnerBoxLowerLinesLeftFlex {
    display:flex;
}

.ExamRecommendationFormTitleText {
    margin-block: auto;
    padding-inline-end: 0.8rem;
}

.ExamRecommendationFormTitleEliminateBlock {
    font-size: 1.2rem;
    font-weight: 800;
    color: #8D96AD;
    cursor: pointer;
}

.ExamRecommendationFormInnerBoxUpperRow {
    justify-content: space-between;
    padding-block: 0.5rem;
    font-weight: 500;
    padding-inline: 0.5rem;
    background-color: #F2F2FF;
    margin-block-end: 0.5rem;
    color: #330066;
}

.ExamRecommendationFormInnerBoxUpperRowText {
    width: 12rem;
    font-size: 0.9rem;
    font-weight: 500;
    padding-inline-end: 1rem;
    margin-block: auto;
}

.ExamRecommendationFormInnerBoxUpperRowRightSide {
    display: flex;
}

.ExamRecommendationFormInnerBoxUpperRowExamIcon,
.ExamRecommendationFormInnerBoxUpperRowPageIcon {
    width: 1.1rem;
    margin-block: auto;
    height: 1.1rem;
    margin-inline: 0.3rem;
}

.ExamRecommendationFormInnerBoxUpperRowTrashcanIcon {
    width: 1.1rem;
    margin-block: auto;
    height: 1.1rem;
    margin-inline-start: 2.3rem;
    cursor: pointer;
}

.ExamRecommendationFormInnerBoxLowerLinesLeftText {
    margin-inline-start: 0.3rem;
    margin-block: auto;
}

.ExamRecommendationFormInnerBoxHorizontalLine {
    border-top: 1px solid #BFD4E4;
}

.ExamRecommendationFormInnerBoxLowerLinesFlex {
    justify-content: space-between;
    margin-block: 1vh;
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 0.9rem;
    padding-inline-end: 2.5rem;
}

.ExamRecommendationFormInnerBoxShowDetails {
    font-weight: 400;
    font-size: 0.7rem;
    text-decoration: underline;
    justify-content: center;
    margin-block: 2vh;
    cursor: pointer;
    width:fit-content
}

.ExamRecommendationFormInnerBoxShowDetailsText {
    text-align: center;
    margin-block: auto;
}

.ExamRecommendationFormInnerBoxEyeIcon {
    width: 2vh;
    height: 2vh;
    margin-inline-start: 0.5vh;
    margin-block: auto;
}

.ExamRecommendationFormInnerBoxExamLinesFlex {
    justify-content: space-between;
}

.ExamRecommendationFormInnerBoxExamLineExamBlockContainer {
    width: 95%;
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    border-right: 1px solid #CFF9FF;
}

.ExamRecommendationFormInnerBoxExamLineExamBlock {
    width: 31%;
    margin-block: 1vh;
    margin-inline: 1%;
}

.ExamRecommendationFormInnerBoxExamLineExamIcon {
    width: 1rem;
    margin-block: auto;
    height: 1rem;
    margin-inline-end: 0.3vw;
}

.ExamRecommendationFormInnerBoxExamLineExamName {
    margin-block: 1vh;
    font-weight: 600;
    font-size: 0.72rem;
    line-height: 0.8rem;
}

.ExamRecommendationFormInnerBoxExamLineExamDescription {
    color: #A3AED0;
    font-weight: 600;
    font-size: 0.8rem;
    line-height: 0.8rem;
}

.ExamRecommendationFormInnerBoxExamLineArrowBlock {
    padding-block: 5vh;
    width: 5%;
    cursor: pointer;
    padding-inline: 0.5vw;
}

.ExamRecommendationFormInnerBoxExamLineRightArrow,
.ExamRecommendationFormInnerBoxExamLineLeftArrow {
    font-size: 1.2rem;
}

.ExamRecommendationFormInnerBoxLowerLinesPageDots {
    justify-content: left;
    padding-inline-start: 0.2rem;
}

.ExamRecommendationFormInnerBoxLowerLinesIndividualPageDot {
    width: 8px;
    height: 8px;
    margin-inline: 2px;
    margin-block: 0.5rem;
    background-color: #CFF9FF;
    border-radius: 50%;
}

.ActiveDot {
    background-color: #330066;
}

.ExamRecommendationFormInnerBoxRiskLines {
    flex-flow: row wrap;
}

.ExamRecommendationFormInnerBoxLowerLineRiskBlock {
    width: 33%;
    margin-block: 1vh;
    margin-inline: 1%;
}

/* Pack Blocks */
.ExamRecommendationFormUpperTitleFlex {
    background-color: #6633FF;
    color: white;
    padding-inline: 1vh;
    font-weight: 500;
}

.ExamRecommendationFormTitleFlex,
.ExamRecommendationFormUpperTitleFlex {
    display: flex;
    justify-content: space-between;
}

.ExamRecommendationFormUpperTitleText {
    margin-block: auto;
}

.ExamRecommendationFormUpperTitleRightSide {
    padding-block: 0.5rem;
    padding-inline: 1.5rem;
    margin-inline-end: 3.2rem;
    border-inline: 1px solid #F2F2FF;
    font-weight: 500;
}

.ExamRecommendationFormInnerBoxUpperRowPackPrice {
    font-size: 0.9rem;
    margin-block: auto;
    margin-inline-start: 1.5rem;
    width: 3.9rem;
    text-align: center;
}

.ExamRecommendationFormInnerDiv2 {
    margin-block: 2rem;
    color: #340066;
    background-color: #EFEFFF;
    border: 1px solid #CFF9FF;
    border-radius: 10px;
    padding-block: 1rem;
    padding-inline: 5%;
}

.ExamRecommendationFormInnerDiv2Flex,
.ExamRecommendationFormInnerDiv2Flex2 {
    display: flex;
}

.ExamRecommendationFormInnerDiv2ExamediLogo {
    height: 2rem;
    width: auto;
    margin-block: auto;
}

.ExamRecommendationFormInnerDiv2Title {
    margin-inline-start: 2%;
    font-weight: 600;
    font-size: 1rem;
}

.ExamRecommendationFormInnerDiv2Subtitle {
    margin-block: 0.5rem;
}

.ExamRecommendationFormInnerDiv2Checkbox {
    margin-block: auto;
}

.ExamRecommendationFormInnerDiv2CheckboxText {
    margin-block: auto;
    margin-inline-start: 2%;
    color: #4773FF;
    font-weight: 600;
}

.ExamRecommendationFormInnerDiv2LowerBlock {
    margin-block: 0.5rem;
}

.ExamRecommendationFormInnerDiv2BlockHidden {
    display: none;
}

.ExamRecommendationFormInnerDiv2LowerBlockInstructions {
    font-size: 0.8rem;
    margin-block-end: 0.5rem;
}

.ExamRecommendationFormInnerDiv2LowerBlockSelector {
    margin-block: 0.5rem;
}

.ExamRecommendationFormInnerDiv2LowerBlockSelectorTile {
    background-color: white;
    border: 2px solid rgb(204, 204, 255);
    padding-block: 0.2rem;
    padding-inline: 0.75rem;
}

.ExamRecommendationFormInnerDiv2LowerBlockInputData {
    display: flex;
    margin-block-end: 0.5rem;
}

.ExamRecommendationFormInnerDiv2LowerBlockInput {
    border-radius: 5px;
    background-color: #ffffff;
    padding-block: 0.5rem;
    padding-inline: 0.75rem;
    width: 100%;
    color: #330066;
}

.ExamRecommendationFormInnerDiv2LowerBlockInputNumber {
    color: #330066;
    margin-block: auto;
    font-size: 1.1rem;
    margin-inline-end: 0.5rem;
}

.ExamRecommendationFormHidden {
    display: none;
}

.ExamRecommendationFormInnerDivPriceFlex {
    display: flex;
    justify-content: space-between;
    margin-block-start: 1rem;
    margin-block-end: 0.5rem;
    font-weight: 500;
    font-size: 1.05rem;
}

.ExamRecommendationFormInnerDivPriceFlexText,
.ExamRecommendationFormInnerDivPriceFlexText2 {
    margin-block: auto;
}

.ExamRecommendationFormInnerDivPriceFlexText2 {
    font-size: 1.2rem;
}

.ExamRecommendationFormInnerDivPriceFlexLowerText {
    font-size: 0.9rem;
}

@media (max-width: 470px) {
    .ExamRecommendationFormInnerBoxExamLineExamBlock {
        width: 98%;
        padding-inline-start: 3%;
    }

    .ExamRecommendationFormInnerBoxExamLineArrowBlock {
        margin-block: auto;
        width: 10%;
        text-align: center;
    }

    .ExamRecommendationFormInnerBoxLowerLineRiskBlock {
        width: 98%;
    }

    .ExamRecommendationFormInnerBoxExamLineRightArrow,
    .ExamRecommendationFormInnerBoxExamLineLeftArrow {
        font-size: 1.2rem;
    }

    .ExamRecommendationFormInnerBox {
        margin-inline: 0.3rem;
        padding-inline-start: 0.2rem;
    }

    .ExamRecommendationFormUpperTitleRightSide {
        margin-inline-end: 7vw;
        padding-inline: 0.5rem;
    }

    .ExamRecommendationFormInnerBoxUpperRowText {
        width: 10rem;
        padding-inline-end: 0.3rem;
    }

    .ExamRecommendationFormInnerBoxUpperRowPackPrice {
        font-size: 0.8rem;
        margin-block: auto;
        margin-inline-start: 3vw;
        width: 4.5rem;
    }

    .ExamRecommendationFormInnerBoxShowDetailsText {
        font-size: 0.6rem;
    }

    .ExamRecommendationFormInnerBoxUpperRowTrashcanIcon {
        margin-inline-start: 3vw;
    }

    .ExamRecommendationFormInnerBoxExamLineExamBlockContainer {
        width: 80%;
    }

    .ExamRecommendationFormInnerDiv2Flex {
        display: block;
    }

    .ExamRecommendationFormInnerDiv2ExamediLogo {
        margin-block-end: 1rem;
    }

    .ExamRecommendationFormInnerDiv2Title {
        margin-inline: 0px;
    }
}

@media (max-width: 320px) {
    .ExamRecommendationFormInnerBoxUpperRowText {
        font-size: 4vw;
    }
}