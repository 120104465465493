.ExamRecommendationBasket {
    padding-inline-start: 2vw;
}

.ExamRecommendationBasketProfileInfoDiv {
    border: 1px solid #F2F2FF;
    border-radius: 10px;
    padding-inline: 1.5vh;
    padding-block-start: 1vh;
}

.ExamRecommendationBasketProfileInfoTitleRow,
.ExamRecommendationBasketProfileInfoTabsFlex,
.ExamRecommendationBasketSubTotalFlex,
.ExamRecommendationBasketTotalFlex,
.ExamRecommendationBasketCouponFlex {
    display: flex;
}

.ExamRecommendationBasketProfileInfoTitleRow {
    margin-block-end: 1rem;
}

.ExamRecommendationBasketProfileInfoTitleRowProfileIcon {
    width: 1.3rem;
    height: 1.3rem;
    margin-block: auto;
}

.ExamRecommendationBasketProfileInfoTitle {
    font-size: 1.1rem;
    font-weight: 500;
    font-family: 'Meltmino', sans-serif;
    margin-block: auto;
    padding-block-start: 0.2rem;
    color: #330066;
    margin-inline-start: 1vh;
}

.ExamRecommendationBasketProfileInfoTabBlockLeft,
.ExamRecommendationBasketProfileInfoTabBlockRight {
    width: 50%;
    text-align: center;
    font-size: 0.8rem;
    border-bottom: 1px solid #BFD4E4;
    padding-block: 1rem;
    color: #043778;
    cursor: pointer;
}

.ExamRecommendationBasketProfileInfoTabActiveBlock {
    border-bottom: 3px solid #6633FF;
    font-weight: 600;
    cursor:default;
}

.ExamRecommendationBasketProfileInfoPatientDataFlex,
.ExamRecommendationBasketTOSFlex {
    display: flex;
}

.ExamRecommendationBasketProfileInfoPatientDataType {
    margin-block: auto;
    width: 5rem;
    text-align: center;
    color:#330066;
}

.ExamRecommendationBasketProfileInfoPatientName,
.ExamRecommendationBasketProfileInfoPatientRut,
.ExamRecommendationBasketProfileInfoPatientAge,
.ExamRecommendationBasketProfileInfoPatientEmail,
.ExamRecommendationBasketProfileInfoPatientGender {
    border: 1px solid #CFF9FF;
    border-radius: 5px;
    color: #330066;
    padding-block: 0.5rem;
    padding-inline: 0.7rem;
    margin-block: 0.5rem;
    font-size: 0.9rem;
    width: 100%;
    overflow-x: scroll;
}

.ExamRecommendationBasketGetOrderDiv {
    margin-block-start: 2vh;
    padding-inline: 1.5vh;
    padding-block: 1vh;
    border-radius: 10px;
    border: 1px solid #F2F2FF;
    color: #330066;
}

.ExamRecommendationBasketGetOrderTitle {
    padding-block: 0.5rem;
    border-bottom: 1px solid black;
    color: #330066;
    font-family: 'Meltmino', sans-serif;
    font-weight: 500;
    font-size: 1.2rem;
    letter-spacing: 1px;
}

.ExamRecommendationBasketGetOrderButton {
    background-color: #6633FF;
    color: white;
    text-align: center;
    padding-block: 1rem;
    border-radius: 10px;
    margin-block-start: 1rem;
    border: 1px solid #9747FF;
}

.ExamRecommendationBasketProfileInfoChangePatientData {
    margin-block-start: 0.5rem;
    color: #330066;
}

.ExamRecommendationBasketProfileInfoChangePatientDataAge,
.ExamRecommendationBasketProfileInfoChangePatientDataGender {
    display: flex;
    margin-inline-start: 1vw;
    margin-block: 0.5rem;
}

.ExamRecommendationBasketProfileInfoChangePatientDataAgeText,
.ExamRecommendationBasketProfileInfoChangePatientDataGenderText {
    margin-block: auto;
    margin-inline-end: 0.5rem;
}

.ExamRecommendationBasketProfileInfoChangePatientDataButton {
    background-color: #6633FF;
    color: white;
    text-align: center;
    padding-block: 0.5rem;
    border-radius: 10px;
    margin-block: 0.5rem;
}

.ExamRecommendationBasketCouponDiv {
    margin-block: 1rem;
}

.ExamRecommendationBasketCouponText {
    margin-block: 0.5rem;
}

.ExamRecommendationBasketCouponValidateButton {
    background-color: #6633FF;
    color: white;
    border: 1px solid #CFF9FF;
    text-align: center;
    width: fit-content;
    padding-block: 0.5rem;
    padding-inline: 0.5rem;
    border-radius: 8px;
}

.ExamRecommendationBasketCouponValidateButtonDisabled {
    background-color: #ae9ce6;
    color: white;
    border: 1px solid #CFF9FF;
    text-align: center;
    width: fit-content;
    padding-block: 0.5rem;
    padding-inline: 0.5rem;
    border-radius: 8px;
}

.ValidOrNotCoupon {
    font-weight: 600;
    color: #6633FF
}

.ExamRecommendationBasketEmailInfoText {
    font-size: 0.8rem;
    margin-block-end: 0.5rem;
}

.ExamRecommendationBasketEmailInfoLink {
    text-decoration: underline;
    color:#09b6dc;
    cursor: pointer;
}

.ExamRecommendationBasketEmailInput {
    height: 2.5rem
}

.ExamRecommendationBasketTOSCheck {
    margin-block: auto;
}

.ExamRecommendationBasketSubTotalFlex,
.ExamRecommendationBasketTotalFlex {
    justify-content: space-between;
    font-family:  monospace, sans-serif;
    border-bottom: 1px solid #939BB3;
    margin-block: 1rem;
}

.ExamRecommendationBasketGetOrderButton:hover,
.ExamRecommendationBasketProfileInfoChangePatientDataButton:hover,
.ExamRecommendationBasketCouponValidateButton:hover {
    background-color: #dcb3ff;
    color: black;
    cursor: pointer;
    transition: all 0.3s;
}

.ExamRecommendationBasketTOSAlert {
    color: red;
    font-size: var(--generalText);
    margin-block-start: 1rem;
}

@media (max-width: 920px) {
    .ExamRecommendationBasket {
        padding-inline-start: 0vw;
        margin-block: 2rem;
    }
}