.ExamChoiceByGenderRoutePage {
    align-items: top;
}

.ExamChoiceByGenderRouteNavBar {
    z-index: 2;
}

.ExamChoiceByGenderAllOuter {
    max-width: 1140px;
    margin-inline: auto;
    margin-block-start: var(--topPageMargin);
}

.ExamChoiceByGenderAll {
    display: flex;
    justify-content: space-around;
    margin-inline: 1%;
    color: #330066;
}

.ExamChoiceByGenderLeftSide,
.ExamChoiceByGenderRightSide {
    width: 48%;
    margin-inline: 1%;
}

.ExamChoiceByGenderLeftSide {
    height:fit-content;
}

.ExamChoiceByGenderLeftSideUpperBlock {
    border: 2px solid #F2F2FF;
    border-radius: 15px;
    margin-block-end: 1rem;
}

.ExamChoiceByGenderPackBoxImage {
    width: 100%;
    height: 17rem;
    border-radius: 15px;
}

.ExamChoiceByGenderPackBoxImageGradient {
    background: linear-gradient(0deg, rgba(207,249,255,1) 0%, rgba(255,255,255,0) 100%);
    width: 100%;
    height: 100%;
}

.ExamChoiceByGenderPackTextContainter {
    padding-inline: 5%;
}

.ExamChoiceByGenderPackTitle {
    text-align: center;
    font-family: 'Meltmino', sans-serif;
    font-weight: 500;
    margin-block: 1rem;
}

.ExamChoiceByGenderPackDescription,
.ExamChoiceByGenderPackProWarning {
    margin-block: 1.5rem;
    font-size: var(--generalText);
}

.ExamChoiceBySponsorPackPriceFlex,
.ExamChoiceByGenderPackPriceFlex {
    display: flex;
    justify-content: space-between;
    margin-block-end: 1rem;
}

.ExamChoiceByGenderPackPrice {
    font-family: monospace, sans-serif;
    margin-block: auto;
    font-size: 1.5rem;
    margin-inline-end: 1rem;
    transition: all .2s ease-in-out;
}

.ExamChoiceByGenderPackPriceFree {
    font-family: monospace, sans-serif;
    margin-block: auto;
    font-size: 1.7rem;
    font-weight: 500;
    margin-inline-end: 1rem;
}

.PriceChanged {
    font-size: var(--bigpriceText);
}

.ExamChoiceByGenderPackAddToOrderButton,
.ExamChoiceByGenderPackAddToOrderButtonDisabled {
    background-color: #6633FF;
    border: 1px solid #CFF9FF;
    color: white;
    width: fit-content;
    padding-block: 0.5rem;
    padding-inline: 1rem;
    border-radius: 10px;
    font-size: 1.1rem;
    font-weight: 600;
    text-align: center;
}

.ExamChoiceByGenderPackAddToOrderButton:hover {
    background-color: #dcb3ff;
    color: black;
    cursor: pointer;
    transition: all 0.3s;
}

.ExamChoiceByGenderPackAddToOrderButtonDisabled {
    background-color: #ae9ce6;
}

.ExamChoiceBySponsorPackSponsorFlex {
    display: flex;
    justify-content: space-between;
    margin-block-end: 1rem;
}

.ExamChoiceBySponsorPackSponsorText {
    padding-block-start: 5%;
}

.ExamChoiceBySponsorPackSponsorImage {
    width: 60%;
    height: auto;
    margin-block: auto;
}

.ExamChoiceBySponsorPackSponsorImage2 {
    width: 48%;
    height: auto;
    margin-block: auto;
}

.ExamChoiceByGenderUpperTags {
    display: flex;
    align-items: center;
}

.ExamChoiceByGenderFemaleTag,
.ExamChoiceByGenderMaleTag {
    width: 100%;
    text-align: center;
    justify-content: center;
    font-size: 1.2rem;
    font-weight: 500;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding-block: 0.5rem;
    background-color: #F2F2FF;
    border-top: 1px solid #CFF9FF;
    border-left: 1px solid #CFF9FF;
    border-right: 1px solid #CFF9FF;
    cursor: pointer;
}

.ExamChoiceByGenderActiveTag {
    background-color: #6633FF;
    color: white;
    cursor:default;
}

.ExamChoiceByGenderChoiceContainer {
    padding-inline: 2vh;
    padding-block: 2vh;
    border-left: 2px solid #F2F2FF;
    border-bottom: 2px solid #F2F2FF;
    border-right: 2px solid #F2F2FF;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.ExamChoiceByGenderChoiceRightText {
    text-align: right;
    color: #330066;
    font-size: 2.3vh;
    letter-spacing: 2px;
    margin-block-end: 1rem;
}

.ExamChoiceByGenderChoiceDescription {
    padding-block: 1vh;
    font-size: var(--generalText);
}

.ExamChoiceByGenderChoiceLabel {
    font-weight: 500;
    font-size: 1.3rem;
    padding-block-start: 1rem;
    padding-block-end: 0.5rem;
    border-bottom: 2px solid;
}

.ExamChoiceByGenderChoiceExamsFlexed {
    display: flex;
    margin-block: 0.4rem;
}

.ExamChoiceByGenderChoiceExamSelect {
    margin-block: auto;
}

input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    padding: 0.1rem;
    border: 1px solid #CCCCFF;
    outline: 1px solid #6633FF;
    border-radius: 10px;
    height: 1rem;
    padding-inline: 0.45rem;
    background-color: white;
    cursor: pointer;
}

input[type="checkbox"]:hover {
    background-color: #CCCCFF;
    border: 1px solid #6633FF;
}

input[type="checkbox"]:checked {
    background-color: #6633FF;
    border: 1px solid #CCCCFF;
}

.ExamChoiceByGenderChoiceExamName,
.ExamChoiceByGenderChoiceChooseAllText {
    padding-inline-start: 1vw;
}

.ExamChoiceByGenderChoiceExamGroupName {
    padding-inline-start: 1vw;
    font-weight: 600;
}

.ExamChoiceByGenderChoiceExamPrice {
    color: #330066;
    font-weight: 600;
    margin-inline-start: 0.5rem;
}

.ExamChoiceByGenderChoiceExamPRO {
    background-color: #CFF9FF;
    color: #330066;
    border-radius: 10px;
    font-weight: 600;
    height: 1.2rem;
    padding-inline: 0.5rem;
    width:fit-content;
}

.ExamChoiceByGenderChoiceChooseAllDiv {
    margin-block-start: 1rem;
}

.ExamChoiceByGenderChoiceChooseAllText {
    font-size: 1.2rem;
}

.ExamChoiceByGenderChoiceExamInfo {
    background-color: #D9D9D9;
    margin-inline-start: 0.5rem;
    margin-block: auto;
    padding-inline: 0.4rem;
    line-height: 1.3rem;
    height: fit-content;
    border-radius: 5px;
    border: 1px solid;
    position: relative;
    cursor: pointer;
}

.ExamChoiceByGenderChoiceExamInfo .ExamChoiceByGenderChoiceTooltip {
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding-inline: 1vh;
    width: 25vh;
  
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    left: 110%;
  }
  
.ExamChoiceByGenderChoiceExamInfo:hover .ExamChoiceByGenderChoiceTooltip {
  visibility: visible;
}

.ExamChoiceByGenderChoiceSpecifics {
    max-height: 500px;
    overflow: hidden;
    transition: 0.3s;
    margin-inline-start: 1.5rem;
    font-size: 0.95rem;
    margin-block-end: 1.5rem;

    &.InformationCollapsed {
        max-height: 0px;
        transition: 0.1s;
        margin-block-end: 0rem;
    }
}

/* Exams display */
.ExamChoiceByGenderAllExamsDivContainer {
    margin-inline: 1rem;
}

.ExamChoiceByGenderAllExamsDiv {
    border: 2px solid #F2F2FF;
    border-radius: 15px;
    height:fit-content;
    max-width: 1140px;
    margin-inline: auto;
    margin-block: 3rem;
    padding-inline: 1rem;
    padding-block: 2rem;
}

.ExamChoiceByGenderAllExamsPageIcon,
.ExamChoiceByGenderAllExamsExamIcon {
    width: 1rem;
    height: 1rem;
    margin-block: auto;
}

.ExamChoiceByGenderAllExamsDerivations {
    margin-block-start: 1.5rem;
}

.ExamChoiceByGenderAllExamsHorizontalLine {
    border-top: 1px solid #BFD4E4;
    width: 100%;
    margin-block: 0.3rem;
}

.ExamChoiceByGenderAllExamsFlexFullLine,
.ExamChoiceByGenderAllExamsFlexExamLine {
    display: flex;
    justify-content: space-between;
}

.ExamChoiceByGenderAllDerivationsFlexExamLine {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
}

.ExamChoiceByGenderAllExamsFlexExamLine {
    width: 90%;
    border-right: 1px solid #CFF9FF;
}

.ExamChoiceByGenderAllDerivationsFlexExamLine {
    width: 100%;
    border-right: 1px solid #CFF9FF;
}

.ExamChoiceByGenderAllExamsLeftArrowDiv,
.ExamChoiceByGenderAllExamsRightArrowDiv {
    width: 5%;
    text-align: center;
    cursor: pointer;
}

.ExamChoiceByGenderAllExamsLeftArrow,
.ExamChoiceByGenderAllExamsRightArrow {
    height: 1.2rem;
    margin-block: 2rem;
}

.ExamChoiceByGenderAllExamsSingleExamBlock {
    width: 100%;
    border-left: 1px solid #CFF9FF;
    padding-inline: 0.5rem;
    padding-block: 0.2rem;
    margin-block: 0.3rem;
}

.ExamChoiceByGenderAllDerivationsSingleExamBlock {
    width: 25%;
    border-left: 1px solid #CFF9FF;
    padding-inline: 0.5rem;
    padding-block: 0.2rem;
    margin-block: 0.3rem;
}

.ExamChoiceByGenderAllExamsSingleExamBlockUpLine,
.ExamChoiceByGenderAllExamsDotsDiv {
    display: flex;
}

.ExamChoiceByGenderAllExamsPageMainTitle {
    font-family: 'Meltmino', sans-serif;
    font-weight: 400;
    letter-spacing: 1px;
    color: #330066;
}

.ExamChoiceByGenderAllExamsSingleExamBlockName {
    margin-block: auto;
    font-weight: 600;
    color: #330066;
    font-size: var(--questionsText);
}

.ExamChoiceByGenderAllExamsSingleExamBlockDescr {
    color: #A3AED0;
    margin-block: 0.2rem;
    font-weight: 500;
    font-size: var(--generalText);
    line-height: 1rem;
}

/* Pack Recommendations */
.ExamChoiceByGenderPacksDiv {
    justify-content: center;
    background-color: white;
    border: 1px solid #F2F2FF;
    border-radius: 10px;
    padding-block-start: 1rem;
    padding-block-end: 2rem;
    padding-inline: 2%;
    margin-inline: 1%;
    margin-block: 4rem;
    max-width: 1140px;
    margin-inline: auto;
}

.ExamChoiceByGenderPacksUpTitle {
    background-color: #CFF9FF;
    padding-block: 0.5rem;
    padding-inline: 1rem;
    border-radius: 10px;
    margin-inline: auto;
    width: fit-content
}

.ExamChoiceByGenderPacksTitle {
    text-align: center;
    font-size: 1.7rem;
    margin-block: 1rem;
    font-family: 'Meltmino', sans-serif;
    color:#330066;
}

.ExamChoiceByGenderPacksPackBoxesFlex,
.ExamChoiceByGenderPacksPackBoxesFullLineFlex {
    display: flex;
    justify-content: center;
}

.ExamChoiceByGenderPacksLeftArrowDiv,
.ExamChoiceByGenderPacksRightArrowDiv {
    cursor: pointer;
}

.ExamChoiceByGenderPacksLeftArrow,
.ExamChoiceByGenderPacksRightArrow {
    height: 1.8rem;
    width: 1.8rem;
    margin-block: 9rem;
    padding-inline: 1vw;
}

.ExamChoiceByGenderPacksPackBox {
    width: 75%;
    margin-inline: 1%;
    margin-block-end: 1rem;
    padding-block-start: 6rem;
    padding-block-end: 2rem;
    position: relative;
}

.ExamChoiceByGenderPacksPackBoxWhiteBox {
    margin-inline: 10%;
    background-color: white;
    border: 1px solid #F2F2FF;
    border-radius: 10px;
    padding-inline: 1.5rem;
    padding-block: 1.5rem;
    position: relative;
}

.ExamChoiceByGenderPacksPackBoxWhiteBoxTitle {
    line-height: 1rem;
    font-family: 'Meltmino', sans-serif;
    font-size: 1.1rem;
    color:#330066;
}

.ExamChoiceByGenderPacksPackBoxWhiteBoxDescription {
    line-height: 1.2rem;
    font-size: 0.9rem;
    margin-block: 1rem;
    color:#330066;
}

.ExamChoiceByGenderPacksPackBoxWhiteBoxFlex {
    display: flex;
    justify-content: space-between;
}

.ExamChoiceByGenderPacksPackBoxWhiteBoxPrice {
    font-family: monospace, sans-serif;
    margin-block: auto;
    font-size: 1rem;
    color:#330066;
}

.ExamChoiceByGenderPackBoxWhiteBoxMoreInfoButton {
    background-color: #6633FF;
    border: 1px solid #CFF9FF;
    color: white;
    width: 100%;
    padding-inline: 5%;
    padding-block: 0.3rem;
    margin-block-start: 0.7rem;
    border-radius: 10px;
    text-align: center;
}

.ExamChoiceByGenderPackBoxWhiteBoxMoreInfoButton:hover {
    background-color: #dcb3ff;
    color: black;
    cursor: pointer;
    transition: all 0.3s;
}

/* Block for heart age calculator */

.ExamChoiceByGenderChoiceCalculatorBlock {
    background-color: #CFF9FF;
    border: 1px solid #CFF9FF;
    border-radius: 10px;
    padding-block: 1rem;
    padding-inline: 4%;
    margin-block: 1rem;
}

.ExamChoiceByGenderChoiceCalculatorBlockExamIcon {
    width: 50px;
    height: 50px;
    margin-inline-start: 0.2rem;
}

.ExamChoiceByGenderChoiceCalculatorBlockUpTitle {
    color: #6633FF;
    letter-spacing: 1px;
    margin-block: 0.5rem;
    font-family: 'Meltmino', sans-serif;
    font-size: var(--epigraphText);
}

.ExamChoiceByGenderChoiceCalculatorBlockTitle {
    color: #6633FF;
    letter-spacing: 1px;
    font-size: 1.4rem;
    line-height: 1.7rem;
    font-weight: 600;
    font-family: 'Meltmino', sans-serif;
}

.ExamChoiceByGenderChoiceCalculatorBlockSubtitle {
    color: #6633FF;
    font-size: var(--generalText);
    padding-block: 1rem;
}

.ExamChoiceByGenderChoiceCalculatorBlockInnerPackButtonFlex {
    display: flex;
    justify-content: center;
    background-color: #6633FF;
    border: 2px solid #CCCCFF;
    border-radius: 15px;
    padding-block: 1rem;
    color: white;
    padding-inline: 1rem;
}

.ExamChoiceByGenderChoiceCalculatorBlockInnerPackButtonFlex:hover {
    cursor: pointer;
    background-color: #EFEFFF;
    color: #6633FF;
    transition: all 0.3s;
    border: 2px solid #6633FF;
}

.ExamChoiceByGenderChoiceCalculatorBlockInnerPackButtonFlex:hover:active {
    background-color: #CCCCFF;
}

.ExamChoiceByGenderChoiceCalculatorBlockInnerPackButtonText {
    margin-block: auto;
    font-weight: 500;
    font-size: var(--buttonText);
}

.ExamChoiceByGenderChoiceCalculatorBlockInnerPackButtonIcon {
    width: 20px;
    height: 20px;
    margin-block: 0.5rem;
}

.ExamChoiceByGenderChoiceCalculatorBlockInnerPackImage {
    width: 100%;
    height: 12rem;
    border-radius: 10px;
    overflow:hidden;
    margin-block-start: 1rem;
}

@media (max-width:720px) {
    .ExamChoiceByGenderAll,
    .ExamChoiceByGenderAllDerivationsFlexExamLine {
        flex-flow: row wrap;
    }
    
    .ExamChoiceByGenderLeftSide,
    .ExamChoiceByGenderRightSide {
        width: 98%;
    }

    .ExamChoiceByGenderPackBoxImage {
        height: 10rem;
    }

    .ExamChoiceByGenderAllDerivationsSingleExamBlock {
        width: 50%;
    }
}

@media (max-width: 620px) {
    .ExamChoiceByGenderPacksPackBox {
        width: 95%;
    }
}

@media (max-width: 520px) {
    .ExamChoiceByGenderMiddleBlocksDiv {
        flex-flow: row wrap;
    }

    .ExamChoiceByGenderMiddleSingleBlock {
        width: 100%;
    }
}

@media (max-width: 420px) {
    .ExamChoiceByGenderAllExamsFlexExamLine {
        width: 80%;
    }
    
    .ExamChoiceByGenderAllExamsLeftArrowDiv,
    .ExamChoiceByGenderAllExamsRightArrowDiv {
        width: 10%;
    }

    .ExamChoiceByGenderAllDerivationsSingleExamBlock {
        width: 100%;
    }
}