.ExamsByPacksInnerDiv {
    margin-block: 3rem;
    max-width: 1140px;
    margin-inline: auto;
    color: #330066;
    margin-block-start: var(--topPageMargin);
}

.ExamsByPacksUpTitle {
    background-color: #CFF9FF;
    width:fit-content;
    margin-inline: auto;
    padding-block: 1vh;
    padding-inline: 2vh;
    border-radius: 10px;
    letter-spacing: 1px;
    font-weight: 600;
    text-align: center;
}

.ExamsByPacksTitleFlex,
.ExamsByPacksGenderTabsFlex {
    display: flex;
    justify-content: center;
}

.ExamsByPacksTitlePlusIcon {
    width: 2.5rem;
    height: auto;
    margin-block: auto;
}

.ExamsByPacksTitle {
    font-weight: 600;
    margin-block: 2vh;
    padding-inline-start: 1rem;
    color: #330066;
    font-family: 'Meltmino', sans-serif;
}

.ExamsByPacksGenderTabsFlex {
    margin-block-start: 1rem;
    margin-inline: 1%;
}

.ExamsByPacksGenderTab {
    width: 33%;
    text-align: center;
    justify-content: center;
    font-size: var(--buttonText);
    font-weight: 500;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding-block: 0.5rem;
    background-color: #F2F2FF;
    border-top: 1px solid #CFF9FF;
    border-left: 1px solid #CFF9FF;
    border-right: 1px solid #CFF9FF;
    cursor: pointer;
}

.ExamsByPacksActiveTag {
    background-color: #6633FF;
    color: white;
    cursor:default;
}

.ExamsByPacksCategoryTabsFlex {
    display: flex;
    justify-content: space-around;
    margin-inline: 1%;
    margin-block-start: 1rem;
}

.ExamsByPacksCategoryTab {
    padding-block: 1rem;
    width: 25%;
    text-align: center;
    border-bottom: 1px solid #BFD4E4;
}

.ExamsByPacksCategoryTab {
    cursor: pointer;
}

.ExamsByPacksActiveCategory {
    border-bottom: 3px solid #6633FF;
    cursor:default;
}

.ExamsByPacksPackBoxesFlex {
    display: flex;
    justify-content: space-around;
    flex-flow: row wrap;
    margin-block-start: 1rem;
}

.ExamsByPacksPackBox {
    width: 30%;
    margin-inline: 1%;
    margin-block-end: 1rem;
    padding-block-start: 6rem;
    padding-block-end: 2rem;
    position: relative;
}

.ExamsByPacksPackBoxBackImage {
    width: 100%;
    height: 10rem;
    position:absolute;
    top: 0px;
    overflow-y: hidden;
}

.ExamsByPacksPackBoxWhiteBox {
    margin-inline: 10%;
    background-color: white;
    border: 1px solid #F2F2FF;
    border-radius: 10px;
    padding-inline: 1.5rem;
    padding-block: 1.5rem;
    position: relative;
}

.ExamsByPacksPackBoxWhiteBoxTitle {
    font-family: 'Meltmino', sans-serif;
    font-weight: 400;
    line-height: 1rem;
    font-size: 1.1rem;
}

.ExamsByPacksPackBoxWhiteBoxDescription {
    font-size: var(--generalText);
    margin-block: 1rem;
    color:#330066;
}

.ExamsByPacksPackBoxWhiteBoxDescriptionHidden {
    display: none;
}

.ExamsByPacksPackBoxWhiteBoxFlex {
    display: flex;
    justify-content: space-between;
}

.ExamsByPacksPackBoxWhiteBoxPrice {
    font-family: monospace, sans-serif;
    margin-block: auto;
    font-size: 1rem;
    color:#330066;
}

.ExamsByPacksPackBoxWhiteBoxAddButton {
    background-color: #6633FF;
    border: 1px solid #CFF9FF;
    color: white;
    width: fit-content;
    padding-block: 0.5rem;
    padding-inline: 1rem;
    border-radius: 10px;
    font-size: 1.2rem;
    font-weight: 600;
}

.ExamsByPacksPackBoxWhiteBoxMoreInfoButton {
    background-color: #6633FF;
    border: 1px solid #CFF9FF;
    color: white;
    width: 100%;
    padding-inline: 5%;
    padding-block: 0.3rem;
    margin-block-start: 0.7rem;
    border-radius: 10px;
    text-align: center;
}

.ExamsByPacksPackBoxWhiteBoxMoreInfoButton:hover {
    background-color: #dcb3ff;
    color: black;
    cursor: pointer;
    transition: all 0.3s;
}

.ExamsByPacksPackBoxWhiteBoxTextOption {
    color: #480090;
    font-weight: 500;
}

.ExamsByPacksPackBoxWhiteBoxTextOption:hover {
    cursor:pointer;
}

@media (max-width:920px) {
    .ExamsByPacksPackBox {
        width: 48%;
    }
    .ExamsByPacksCategoryTabsFlex {
        flex-flow: row wrap;
    }
}

@media (max-width:520px) {
    .ExamsByPacksTitleFlex {
        padding-inline: 10%;
    }

    .ExamsByPacksTitlePlusIcon {
        width: 2rem;
    }

    .ExamsByPacksUpTitle {
        font-size: 0.7rem;
    }

    .ExamsByPacksTitle {
        font-size: 1.5rem;
        text-align: center;
    }

    .ExamsByPacksPackBox {
        width: 98%;
    }
}

@media (max-width:420px) {
    .ExamsByPacksCategoryTab {
        width: 50%;
    }
}