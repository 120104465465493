.Page {
    align-items: center;
}

.DataRouteNavBar {
    z-index: 2;
    position: sticky;
}

.RoutesBar {
    z-index: 2;
    position: sticky;
}

.DataRouteTitle {
    margin-inline: 5%;
    margin-block-start: var(--topPageMargin);
}

.DataRouteForm {
    margin-inline: 5%;
}

.DataRouteFooter {
    margin-block-start: 4rem;
}

@media (max-width:425px) {

    .RecommenderPathData {
       visibility: collapse;
       height: 0;
    }
    
}