.HealthCalculatorDivInner {
    padding-inline: 2%;
    padding-block-start: var(--topPageMargin);
}

.HealthCalculatorDivInnerFlex {
    margin-inline: auto;
    max-width: 1300px; 
    display: flex;
    padding-block: 2rem;
}

.HealthCalculatorDivLeftSide {
    width: 50%;
    color: #330066;
}

.HealthCalculatorDivUpTitle {
    background-color: #CCCCFF;
    padding-block: 1rem;
    padding-inline: 1rem;
    border-radius: 10px;
    width: fit-content;
    font-family: 'Meltmino', sans-serif;
    font-size: var(--epigraphText);
    margin-block-end: 1rem;
}

.HealthCalculatorDivTitle {
    font-family: 'Meltmino', sans-serif;
    color: #6633FF;
    font-weight: 700;
}

.HealthCalculatorDivSubtitle {
    color: #6633FF;
    max-width: 420px;
    font-size: var(--generalText);
    font-weight: 500;
    line-height: 17px;
}

.HealthCalculatorDivDropdownBlock {
    display: flex;
    background-color: #FBFFFF;
    border: 1px solid #CFF9FF;
    justify-content: space-between;
    padding-block: 0.5rem;
    margin-block: 1rem;
    padding-inline: 0.5rem;
    border-radius: 18px;
}

.HealthCalculatorDivDropdown {
    font-family: 'Meltmino', sans-serif;
    font-size: var(--epigraphText);
    color: #6633FF;
    width: 80%;
    border: 0px;
    white-space:pre-wrap;
    margin-inline-end: 1vw;
}

.HealthCalculatorDivRightSide {
    padding-inline: 1%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.HealthCalculatorDivRightSideImage {
    width: 100%;
    height: auto;
    margin-block: auto;
    border-radius: 12px;
}

.HealthCalculatorDivLowerFlex {
    display: flex;
}

.HealthCalculatorDivLowerBlock {
    width: 100%;
    padding-inline: 2%;
    padding-block: 2rem;
}

.HealthCalculatorDivLowerBlockInner {
    max-width: 300px;
    margin-inline: auto;
}

.HealthCalculatorDivRightLowerBlockImage {
    width: 100%;
    height: 200px;
    border-radius: 12px;
}

.HealthCalculatorDivRightLowerBlockTitle {
    font-family: 'Meltmino', sans-serif;
    font-weight: 600;
    margin-block: 20px;
}

.HealthCalculatorDivRightLowerBlockSubtitle {
    font-family: 'Meltmino', sans-serif;
    font-weight: 500;
    letter-spacing: 1px;
}

.HealthCalculatorDivRightLowerBlockSubtitle2 {
    text-align: center;
    font-family: 'Meltmino', sans-serif;
    font-size: var(--epigraphText);
    margin-block: 20px;
    line-height: 22px;
}

@media (max-width: 820px) {
    .HealthCalculatorDivInnerFlex {
        flex-flow: row wrap;
    }
    
    .HealthCalculatorDivLeftSide,
    .HealthCalculatorDivRightSide {
        width: 100%;
        text-align: center;
    }

    .HealthCalculatorDivRightSideImage {
        max-width: 400px;
        margin-inline: auto;
        margin-block: 2rem;
    }

    .HealthCalculatorDivUpTitle,
    .HealthCalculatorDivSubtitle {
        margin-inline: auto;
        
    }
}

@media (max-width: 680px) {
    .HealthCalculatorDivLowerFlex {
        flex-flow: row wrap;
    }

    .HealthCalculatorDivRightLowerBlockTitle,
    .HealthCalculatorDivRightLowerBlockSubtitle {
        text-align: center;
    }
}

@media (max-width: 400px) {
    .HealthCalculatorDivDropdownBlock {
        flex-flow: row wrap;
    }

    .HealthCalculatorDivDropdown {
        width: 100%;
        height: 4rem;
        margin-block-end: 0.5rem;
        display:flex;
    }

    .HealthCalculatorDivDropdownButtonFlex {
        width: 100%;
    }
}