.DataRouteTitleDiv {
    height: 100%;
    justify-content: space-between;
    display: flex;
    border-bottom: 2px solid rgb(52, 0, 102);
    font-size: 2rem;
    font-weight: 600;
    max-width: 1040px;
    margin-inline: auto;
    margin-block-end: 1.5rem;
    color: #330066;
}

.DataRouteTitleText {
    color: rgb(52, 0, 102);
}

.DataRouteTitleProgress {
    padding-inline-start: 2vh;
    justify-content: flex-end;
}

@media (max-width: 440px) {
    .DataRouteTitleDiv {
        font-size: 0.8rem;
    }
}