.DiabetesRiskCalculatorFull {
    padding-inline: 2%;
    padding-bottom: 2%;
    padding-block-start: var(--topPageMargin);
}

.DiabetesRiskCalculatorInnerDiv {
    padding-block: 1rem;
    max-width: 1300px;
    margin: auto;
    display: flex;
    color: #330066;
    overflow-x: hidden;
}

.DiabetesRiskCalculatorLeftSide {
    width: 55%;
}

.DiabetesRiskCalculatorLeftSideUpTitle {
    background-color: #CCCCFF;
    width:fit-content;
    padding-block: 0.75rem;
    padding-inline: 1rem;
    border-radius: 10px;
    font-weight: 600;
    font-size: var(--generalText);
    text-align: center;
}

.DiabetesRiskCalculatorLeftSideTitle {
    margin-block: 1.4rem;
    font-size: 2.9rem;
    font-weight: 700;
    color: #330066;
    font-family: 'Meltmino', sans-serif;
    line-height: 2.8rem;
}

.DiabetesRiskCalculatorLeftSideSubtitle {
    margin-block-start: 1rem;
    margin-block-end: 1.8rem;
    font-weight: 600;
    font-size: 1rem;
    color: #330066;
    padding-right: 5%;
}

.DiabetesRiskCalculatorLeftSideSubtitle2 {
    margin-block-end: 1rem;
}

.DiabetesRiskCalculatorLeftSideImage {
    width: 100%;
    margin-inline: auto;
    position: relative;
}

.DiabetesRiskCalculatorRightSide {
    width: 45%;
    margin-inline-start: 2%;
}

.DiabetesRiskCalculatorRightSideTitleDiv,
.DiabetesRiskCalculatorResultsTitleDiv {
    background-color: #CFF9FF;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding-inline: 1rem;
    padding-block: 0.8rem;
    max-width: 1300px;
}

.DiabetesRiskCalculatorRightSideTitleFlex {
    display: flex;
    align-items: center;
}

.DiabetesRiskCalculatorRightSideTitleIcon {
    width: 50px;
    height: 50px;
    margin-block: auto;
}

.DiabetesRiskCalculatorRightSideTitleInnerDiv {
    margin-block: auto;
}


.DiabetesRiskCalculatorRightSideTitle {
    font-family: 'Meltmino', sans-serif;
    color: #6633FF;
    font-size: 1.2rem;
    font-weight: 600;
    margin-block: auto;
    text-align: right;
}

.DiabetesRiskCalculatorLeftSideSubtitle {
    color: #330066;
}

.DiabetesRiskCalculatorRightSideSurveyTitle {
    font-family: 'Meltmino', sans-serif;
    margin-block-start: 1.5rem;
    padding-block-end: 0.9rem;
    text-align: center;
    font-size: 1.5rem;
    max-width: 25rem;
    font-weight: 500;
    margin-inline: auto;
    color: #330066;
}

.DiabetesRiskCalculatorRightSideSurveyQ1 {
    margin-block: 1rem;
    color: #330066;
    font-weight: 600;
    font-size: 0.9rem;
}

.DiabetesRiskCalculatorRightSideSurveyQ1Answers {
    display: flex;
    width: 70%;
    padding-inline-start: 1rem;
}

.DiabetesRiskCalculatorRightSideSurveyQ1SuggestionText {
    margin-block-end: 1rem;
    color: #330066;
    font-size: 0.9rem;
    max-height: 500px;
    overflow: hidden;
    transition: 0.3s;

    &.InformationCollapsed {
        max-height: 0px;
        transition: 0.1s;
        margin-block-end: 0rem;
    }
}

.DiabetesRiskCalculatorRightSideSurveyQ1SuggestionLink {
    text-decoration: underline;
    cursor: pointer;
}

.DiabetesRiskCalculatorRightSideSurveyQ1AgeInput {
    width: 100%;
}

.DiabetesRiskCalculatorRightSideSurveyQ1Flex,
.DiabetesRiskCalculatorRightSideSurveyQ1TileFlex {
    display: flex;
}

.DiabetesRiskCalculatorRightSideSurveyQ1TileFlex {
    justify-content: space-between;
}

.DiabetesRiskCalculatorRightSideSurveyQ1Tile {
    width: 50%;
    text-align: center;
    border-bottom: 1px solid #BFD4E4;
    padding-block: 0.5rem;
    padding-inline: 0.5rem;
    font-size: 0.9rem;
    cursor: pointer;
}

.DiabetesRiskCalculatorRightSideSurveyQ1Tile:not(.DiabetesRiskCalculatorRightSideSurveyQ1ActiveTile):hover {
    background-color: rgba(207, 249, 255, .5);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.DiabetesRiskCalculatorRightSideSurveyTimeInput {
    margin-block: auto;
}

.DiabetesRiskCalculatorRightSideSurveyQ1ActiveTile {
    background-color: #F2F2FF;
    color: #330066;
    border-bottom: 3px solid #6633FF;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    font-weight: 600;
    cursor: default;
}

.DiabetesRiskCalculatorRightSideSurveyQ1TileIcon {
    width: 50px;
    height: 50px;
    opacity: 0.3;
}

.DiabetesRiskCalculatorRightSideSurveyQ1TileActiveIcon {
    opacity: 1;
}

.DiabetesRiskCalculatorRightSideSurveyQuestionsFlex {
    display: flex;
    justify-content: space-between;
    column-gap: 3%;
}

.DiabetesRiskCalculatorRightSideSurveyQuestion {
    width: 100;
}

.DiabetesRiskCalculatorRightSideSurveyAbdominalPerimeterFlex {
    display: flex;
    align-items: center;
}

.DiabetesRiskCalculatorRightSideSurveyAbdominalPerimeterDiv {
    border: 1px solid #CFF9FF;
    padding-block: 1%;
    padding-inline: 3%;
    width: 65%;
}

.DiabetesRiskCalculatorRightSideSurveyAbdominalPerimeterExplanation {
    width: 35%;
    padding-left: 2%;
    font-size: 0.65rem;
    font-style: italic;
    padding-inline: 3%;
}

.DiabetesRiskCalculatorRightSideSurveyAdvanceBlocksFlex {
    margin-block-start: 3%;
    display: flex;
    justify-content: space-between;
}

.DiabetesRiskCalculatorRightSideSurveyAdvanceBlockDark {
    width: 30%;
    margin-block: 0.5rem;
    padding-block: 0.01rem;
    border-radius: 22px;
    background-color: #6633FF;
}

.DiabetesRiskCalculatorRightSideSurveyAdvanceBlockLight {
    width: 28%;
    margin-block: 0.5rem;
    padding-block: 0.01rem;
    border-radius: 22px;
    background-color: #CFF9FF;
}

.DiabetesRiskCalculatorRightSideSurveyAdvanceBlockText {
    padding-left: 4%;
    font-family: 'Meltmino', sans-serif;
    color: #6633FF;
}

.DiabetesRiskCalculatorRightSideSurveyQ1MeasureFlex {
    display: flex;
    align-items: center;
}

.DiabetesRiskCalculatorRightSideSurveyQ1MeasureFlex2 {
    display: flex;
    column-gap: 3%;
    align-items: center;
    border: 1px solid #CFF9FF;
    padding-inline: 10%;
    border-radius: 12px;
}

.DiabetesRiskCalculatorRightSideModifier {
    padding-block: 0.05%;
    padding-inline: 4%;
    background-color: #CCCCFF;
    border-radius: 4px;
    color: #330066;
    cursor: pointer;
}

.DiabetesRiskCalculatorRightSideSurveyQ4InputTile {
    padding-inline-start: 2vw;
    padding-block: 0.5rem;
    width: 80%;
    color: #330066;
    font-weight: 600;
}

.DiabetesRiskCalculatorRightSideSurveyQ5InputTile {
    padding-inline: 1rem;
    padding-block: 1rem;
    width: 100%;
    background-color: #F2F2FF;
    text-align: center;
    color: #330066;
    font-weight: 600;
}

.DiabetesRiskCalculatorRightSideSurveyQ4InputMeasure {
    padding-inline-start: 0.5rem;
    padding-right: 2%;
    margin-block: auto;
    color: #330066;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
}

.DiabetesRiskCalculatorRightSideSurveyCalculate,
.DiabetesRiskCalculatorRightSideSurveyCalculateDisabled {
    background-color: #6633FF;
    text-align: center;
    padding-block: 1rem;
    color: white;
    font-size: 0.9rem;
    font-weight: 500;
    font-style: italic;
    text-align: center;
    border: 2px solid #CCCCFF;
    border-radius: 10px;
    margin-block-start: 1rem;
    cursor: pointer;
}

.DiabetesRiskCalculatorRightSideSurveyCalculate:hover {
    background-color: #CCCCFF;
    color: #6633ff;
    transition: all 0.3s;
    border: 2px solid #6633FF;
}

.DiabetesRiskCalculatorRightSideSurveyCalculateDisabled {
    cursor: default;
    background-color: #EFEFFF;
    color: #6633FF;
    border: 2px solid #6633FF;
}

.DiabetesRiskCalculatorResultsRightSideEmailButtonText {
    text-align: center;
    width: 90%;
    margin-block: auto;
}

.DiabetesRiskCalculatorResultsRightSideCalculatorIconDiv {
    padding-inline: 1rem;
    border-left: 1px solid white;
    padding-block: 1.5rem;
}

.DiabetesRiskCalculatorResultsRightSideCalculatorIcon {
    width: 2rem;
    height: 2rem;
}

.DiabetesRiskCalculatorBibliographyFlex {
    display: flex;
}

.DiabetesRiskCalculatorBibliography {
    font-size: 1.2rem;
    color:#330066;
}

.DiabetesRiskCalculatorBibliographyArrow {
    color:#330066;
    margin-block: auto;
}

.DiabetesRiskCalculatorBibliographyContent {
    font-size: 0.9rem;
    margin-block-start: 0.7rem;
    max-height: 500px;
    transition: all 0.5s ease;
    overflow: hidden;

    &.DiabetesRiskCalculatorBibliographyContentHidden {
        max-height: 0px;
        transition: all 0.5s ease;
        margin-block-start: 0rem;
    }
}

.DiabetesRiskCalculatorResultsTitleYMCLogo {
    width: 73px;
    height: auto;
    margin-block: auto;
}

.DiabetesRiskCalculatorResultsTitleIcon {
    width: 50px;
    height: 50px;
    margin-block: auto;
}

.DiabetesRiskCalculatorResultsRightSidePackBlock {
    background-color: #EFEFFF;
    border: 1px solid #CFF9FF;
    border-radius: 10px;
    padding-block: 1rem;
    padding-inline: 7%;
    margin-block-start: 1rem;
}

.DiabetesRiskCalculatorResultsRightSidePackBlockExamIcon {
    width: 50px;
    height: 50px;
    margin-inline-start: 0.2rem;
}

.DiabetesRiskCalculatorResultsRightSidePackBlockUpTitle {
    color: #6633FF;
    letter-spacing: 1px;
    margin-block: 0.5rem;
    font-family: 'Meltmino', sans-serif;
}

.DiabetesRiskCalculatorResultsRightSidePackBlockTitle {
    color: #6633FF;
    letter-spacing: 1px;
    font-size: 1.4rem;
    line-height: 1.7rem;
    font-weight: 600;
    margin-block-end: 0.5rem;
    font-family: 'Meltmino', sans-serif;
}

.DiabetesRiskCalculatorResultsRightSidePackBlockSubtitle {
    color: #330066;
    font-size: 0.75rem;
    border-bottom: 1px solid #330066;
    padding-block-end: 0.5rem;
}

/* Parte dos survey*/

.DiabetesRiskCalculatorPart2 {
    border: 1px solid #CFF9FF;
    background-color: #FBFFFF;
    color: #330066;
    padding-inline: 2vw;
    padding-block: 2rem;
    border-radius: 8px;
    margin-block: 1rem;
    margin-inline: auto;
    max-width: 800px;
}

.DiabetesRiskCalculatorPart2TitleFlex {
    background-color: #CFF9FF;
    border-radius: 10px;
    display: flex;
    padding-inline: 5%;
    padding-block: 2%;
}

.DiabetesRiskCalculatorPart2RightSide {
    padding-inline-start: 1rem;
}

.DiabetesRiskCalculatorPart2RightSideTitleFlex {
    background-color: #CFF9FF;
    border-radius: 10px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding-block: 0.5rem;
}

.DiabetesRiskCalculatorPart2Title {
    font-family: 'Meltmino', sans-serif;
    color: #6633FF;
    font-size: 1.2rem;
    font-weight: 600;
    margin-block: auto;
    text-align: left;
}

.DiabetesRiskCalculatorPart2RightSideSubtitle {
    font-family: 'Meltmino', sans-serif;
    color: #330066;
    font-weight: 537;
}

.DiabetesRiskCalculatorRightSideSurveyTitlePart2 {
    font-family: 'Meltmino', sans-serif;
    margin-block-start: 1.5rem;
    padding-block-end: 0.9rem;
    text-align: left;
    font-size: 1.5rem;
    font-weight: 500;
    color: #330066;
}

.Part2Question{
    width: 50%;
}

.DiabetesRiskCalculatorPart2SurveyQ1Tile {
    width: 50%;
    text-align: center;
    border-bottom: 1px solid #BFD4E4;
    padding-block: 1rem;
    padding-inline: 0.5rem;
    margin-block: 2%;
    font-size: 0.9rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.DiabetesRiskCalculatorPart2SurveyQ1Tile:not(.DiabetesRiskCalculatorPart2SurveyQ1ActiveTile):hover {
    background-color: rgba(207, 249, 255, .5);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.DiabetesRiskCalculatorPart2SurveyQ1ActiveTile {
    background-color: #F2F2FF;
    color: #330066;
    border-bottom: 3px solid #6633FF;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    font-weight: 600;
    cursor: default;
}

.DiabetesRiskCalculatorPart2FamilyFlex {
    display: flex;
    margin-block: 2%;
    justify-content: space-between;
}

.DiabetesRiskCalculatorPart2FamilyMember {
    width: 30%;
    background-color: #FBFFFF;
    border: 1px solid #CFF9FF;
    border-radius: 6px;
    color: #330066;
    font-weight: 600;
    text-align: center;
    padding-block: 2%;
    margin-inline: 1%;
    cursor: pointer;
}

.DiabetesRiskCalculatorPart2FamilyMember:hover {
    background-color: #EFEFFF;
    color: #330066;
}

.DiabetesRiskCalculatorPart2FamilyMemberActive {
    background-color: #6633FF;
    color: #FBFFFF;
}

.DiabetesRiskCalculatorPart2FamilyMemberActive:hover {
    background-color: #CCCCFF;
}

.DiabetesRiskCalculatorPart2ButtonsFlex {
    display: flex;
    justify-content: space-between;
    margin-block-start: 2rem;
}

.DiabetesRiskCalculatorPart2SurveyButtonText {
    padding-inline: 2vw;
    text-align: center;
}

/* Results */
.DiabetesRiskCalculatorLeftSideResultContainer {
    border: 1px solid #CFF9FF;
    background-color: #FBFFFF;
    color: #330066;
    padding-inline: 2vw;
    padding-block: 2rem;
    border-radius: 8px;
    margin-block-end: 1rem;
    width: 100%;
}

.DiabetesRiskCalculatorResults {
    margin-block: 1%;
    margin-inline: auto;
    max-width: 800px;
    background-color: #FBFFFF;
    padding: 2%;
    border-radius: 12px;
}

.DiabetesRiskCalculatorResultsTitleFlex {
    display: flex;
    justify-content: space-between;
    background-color: #CCCCFF;
    border-radius: 10px;
    padding-inline: 2%;
}

.DiabetesRiskCalculatorResultsTitleInnerFlex {
    display: flex;
}

.DiabetesRiskCalculatorResultsTitleLeftSideImage {
    width: 2.5rem;
    height: auto;
    margin-block: auto;
}

.DiabetesRiskCalculatorResultsTitleBlock {
    margin-block: 2%;
    padding-top: 2%;
    text-align: left;
    margin-inline-start: 1rem;
}

.DiabetesRiskCalculatorResultsUpTitle,
.DiabetesRiskCalculatorResultsTitle,
.DiabetesRiskCalculatorResults1Title {
    font-family: 'Meltmino', sans-serif;
    color: #6633FF;
}

.DiabetesRiskCalculatorResultsTitleRightSideImage {
    width: 2.5rem;
    height: auto;
    margin-block: auto;
}

.DiabetesRiskCalculatorResults1Title {
    text-align: center;
    margin-block: 0.8rem;
}

.DiabetesRiskCalculatorResultsTitlePart1,
.DiabetesRiskCalculatorResultsTitlePart2 {
    font-family: 'Meltmino', sans-serif;
    color: #6633FF;
    margin-block: 0.5rem;
}

.DiabetesRiskCalculatorResultsTitlePart1 {
    text-align: center;
}

.DiabetesRiskCalculatorResultsTitlePart2 {
    text-align: left;
}

.DiabetesRiskCalculatorResultsContainer,
.DiabetesRiskCalculatorResultsContainer2 { 
    max-width: 350px;
    margin-inline: auto;
    border-radius: 12px;
    position: relative;
    height: 15rem;
    margin-block-start: 0rem;
    margin-block-end: 2rem;
    background-size: 80% auto;
}

.DiabetesRiskCalculatorResultsText {
    padding: 2%;
    border-radius: 20px;
    position: absolute;
    right:-8%;
    top: 20%;
    max-width: 200px;
}

.DiabetesRiskCalculatorResultsTextSmallScreen {
    padding-block: 1rem;
    padding-inline: 1rem;
    margin-inline: 2%;
    margin-block-end: 1rem;
    border: 1px solid #CFF9FF;
    border-radius: 20px;
    font-family: 'Meltmino', sans-serif;
    text-align: center;
    display: none;
}

.LowRisk {
    background-color: #8BC53F;
}

.MediumRisk {
    background-color: #FFCD29;
    top: 0%;
}

.HighRisk {
    background-color: #FF4F53;
}

.DiabetesRiskCalculatorResultsTextPart1,.DiabetesRiskCalculatorResultsTextPart1B{
    font-size: 1.3rem !important;
    font-weight: 500;
}

.DiabetesRiskCalculatorResultsTextPart1,
.DiabetesRiskCalculatorResultsTextPart2,
.DiabetesRiskCalculatorResultsTextPart3,
.DiabetesRiskCalculatorResultsTextPart4,
.DiabetesRiskCalculatorResultsTextPart1B,
.DiabetesRiskCalculatorResultsTextPart2B,
.DiabetesRiskCalculatorResultsTextPart3B,
.DiabetesRiskCalculatorResultsTextPart4B {
    font-family: 'Meltmino', sans-serif;
    text-align: center;
}

.DiabetesRiskCalculatorResultsTextPart1,
.DiabetesRiskCalculatorResultsTextPart2,
.DiabetesRiskCalculatorResultsTextPart3,
.DiabetesRiskCalculatorResultsTextPart4 {
    color: #330066;
}

.DiabetesRiskCalculatorResultsTextPart1B,
.DiabetesRiskCalculatorResultsTextPart2B,
.DiabetesRiskCalculatorResultsTextPart3B,
.DiabetesRiskCalculatorResultsTextPart4B {
    color: #FBFFFF;
}

.DiabetesRiskCalculatorResultsTextPart1,
.DiabetesRiskCalculatorResultsTextPart3,
.DiabetesRiskCalculatorResultsTextPart1B,
.DiabetesRiskCalculatorResultsTextPart3B {
    font-size: var(--generalText);
}

.DiabetesRiskCalculatorResultsTextPart2,
.DiabetesRiskCalculatorResultsTextPart2B {
    font-size: 2.5rem;
    font-weight: 700;
}

.DiabetesRiskCalculatorResultsTextPart4,
.DiabetesRiskCalculatorResultsTextPart4B {
    font-size: 1.3rem;
    font-weight: 600;
}

.DiabetesRiskCalculatorResultsPart2,
.DiabetesRiskCalculatorResultsPart3 {
    padding-block: 3%;
    border: 1px solid #CFF9FF;
    border-radius: 8px;
    margin-block-start: 1rem;
}

.DiabetesRiskCalculatorResultsTextPadding {
    margin-inline: 7%;
}

.DiabetesRiskCalculatorResults2Title {
    font-family: 'Meltmino', sans-serif;
    color: #6633FF;
    text-align: left;
    font-weight: 600;
}

.DiabetesRiskCalculatorResultsPart2HealthFactorsImage {
    width: 100%;
    align-items: center;
}

.DiabetesRiskCalculatorResultsPart2HealthFactorsText {
    color: #330066;
    font-size: var(--generalText);
    font-weight: 500;
    margin-inline: 7%;
    margin-block-start: 6%;
    margin-block-end: 4%;
}

.DiabetesRiskCalculatorResultsPart2Button1,
.DiabetesRiskCalculatorResultsPart2Button2 {
    margin-inline: 30%;
    text-align: center;
    border-radius: 12px;
    border: 1px solid #6633FF;
    margin-block: 2rem;
    padding-block: 2%;
    cursor: pointer;
    width: fit-content;
    padding-inline: 2%;
}

.DiabetesRiskCalculatorResultsPart2Button1 {
    background-color: #6633FF;
    color: #EFEFFF;
    margin-left: 29%;
}

.DiabetesRiskCalculatorResultsPart2Button2{
    color: #6633FF;
}

.DiabetesRiskCalculatorResultsPart3HealthFactorsBlocks {
    margin-block: 4%;
    margin-inline: 5%;
}

.DiabetesRiskCalculatorResultsPart3HealthFactorsBlockFlex {
    display: flex;
    align-items: flex-start;
    margin-block: 5%;
}

.DiabetesRiskCalculatorResultsPart3HealthFactorsBlockImageContainer {
    width: 20%;
}

.DiabetesRiskCalculatorResultsPart3HealthFactorsBlockImage1 {
    width: 100%;
}

.DiabetesRiskCalculatorResultsPart3HealthFactorsBlockImage2 {
    width: 125%;
    margin-left: -10%;
}

.DiabetesRiskCalculatorResultsPart3HealthFactorsBlockImage3 {
    width: 120%;
    margin-top: 5%;
}

.DiabetesRiskCalculatorResultsPart3HealthFactorsBlockImage4 {
    width: 130%;
    margin-top: 10%;
}

.DiabetesRiskCalculatorResultsPart3HealthFactorsBlockImage5 {
    width: 120%;
    margin-top: 30%;
}

.DiabetesRiskCalculatorResultsPart3HealthFactorsBlockText {
    width: 80%;
    padding-left: 6%;
}

.DiabetesRiskCalculatorResultsPart3HealthFactorsYears {
    color: #28AEFF;
    font-family: 'Meltmino', sans-serif;
}

.DiabetesRiskCalculatorResultsPart3HealthFactorsTitle {
    color: #330066;
    font-family: 'Meltmino', sans-serif;
    font-weight: 800;
    font-size: 1.2rem;
    margin-block-end: 2%;
}

.DiabetesRiskCalculatorResultsPart3HealthFactorsSubtitle {
    font-family: 'DM Sans', sans-serif;
    color: #330066;
    font-size: 0.96rem;
    padding-right: 5%;
    line-height: 1.1rem;
}

.Italic {
    font-style: italic;
}

.DiabetesRiskCalculatorResultsPart3EndText {
    font-family: 'Meltmino', sans-serif;
    color: #330066;
    font-size: 1rem;
    text-align: center;
    margin-block: 7%;
    margin-inline: 7%;
}

.DiabetesRiskCalculatorResultsRiskBar{
    bottom: -25px;
    left: -10px;
}

.DiabetesRiskCalculatorResultsCircleContain {
    width: 100%;
    display: flex;
}

.DiabetesRiskCalculatorResultsRiskBarCircle{
    filter: drop-shadow(#C6C8CA 0 0.2rem 0.25rem);
    top: -10%;
}

.YNAlert {
    color: red;
    margin-block-end: 1rem;
}

.DiabetesMeterFace{
    height: 100px;
}

.DiabetesResultSubtext{
    color: #330066;
    text-align: center;
    padding-inline: 2%;
    font-size: var(--generalText);
    max-width: 650px;
    margin-inline: auto;
}

.DiabetesResultMedicalOrder {
    margin-inline: auto;
    margin-block-start: 2rem;
}

.DiabetesRiskCalculatorLeftSideSubtitleEnd{
    margin-top: 20px;
    margin-inline: 7%;
}
.DiabetesRiskCalculatorInnerDiv2{
    margin-top: 20px;
    max-width: 800px;
    padding-block-end: 1rem;
    margin-inline: auto;
    display: flex;
    color: #330066;
    overflow-x: hidden;
}

@media (max-width: 880px) {
    .DiabetesRiskCalculatorInnerDiv {
        flex-flow: row wrap;
        max-width: 500px;
    }

    .DiabetesRiskCalculatorRightSide {
        margin-inline-start: 0%;
    }

    .DiabetesRiskCalculatorLeftSideSubtitle {
        margin-block-end: 1rem;
    }

    .DiabetesRiskCalculatorLeftSideUpTitle {
        margin-inline: auto;
    }

    .DiabetesRiskCalculatorLeftSide,
    .DiabetesRiskCalculatorRightSide {
        width: 100%;
    }

    .DiabetesRiskCalculatorRightSide {
        margin-block-end: 1rem;
    }

    .DiabetesRiskCalculatorLeftSideResultTitle,
    .DiabetesRiskCalculatorLeftSideResultPartName {
        text-align: center;
    }

    .DiabetesRiskCalculatorLeftSideSubtitle {
        margin-block-end: 1rem;
    }

    .DiabetesRiskCalculatorLeftSideImage {
        display: none;
    }

    .DiabetesRiskCalculatorResultsRightSide {
        width: 100%;
        margin-inline-end: 0px;
    }

    .DiabetesRiskCalculatorResultsRightSide {
       margin-block-start: 2.5rem;
    }
}

@media (max-width: 640px) {
    .DiabetesRiskCalculatorPart2FamilyFlex {
        flex-flow: row wrap;
    }

    .DiabetesRiskCalculatorPart2FamilyMember {
        margin-block-end: 1rem;
    }
}

@media (max-width: 450px) {

    .DiabetesRiskCalculatorResultsTextSmallScreen {
        display: block;
    }

    .DiabetesRiskCalculatorResultsText {
        display: none;
    }

    .DiabetesRiskCalculatorResultsContainer2,
    .DiabetesRiskCalculatorResultsContainer { 
        background-size: 100% auto;
    }

    .DiabetesRiskCalculatorPart2FamilyMember {
        width: 47%;
    }

    .DiabetesRiskCalculatorRightSideSurveyQ1TileFlex {
        flex-flow: row wrap;
        justify-content: space-between;
    }

    .DiabetesRiskCalculatorRightSideSurveyQ1Answers {
        padding-inline-start: 0px;
        width: 100%;
    }

    .DiabetesRiskCalculatorRightSideSurveyQ1 {
        width: 100%;
    }
    
    .DiabetesRiskCalculatorRightSideSurveyQ1Tile {
        width: 48%;
    }

    .DiabetesRiskCalculatorPart2SurveyQ1Tile {
        width: 50%;
    }
    
    .DiabetesRiskCalculatorLeftSideTitle {
        font-size: 1.7rem;
        line-height: 2rem;
    }

    .DiabetesRiskCalculatorRightSideSurveyQuestionsFlex {
        flex-flow: row wrap;
    }

    .DiabetesRiskCalculatorRightSideSurveyQuestion,
    .DiabetesRiskCalculatorRightSideSurveyQ1AgeInput {
        width: 100%;
    }

    /* .DiabetesRiskCalculatorRightSideTitle {
        font-size: 1rem;
    } */

    .DiabetesRiskCalculatorResultsTitleDiv {
        padding-inline: 0.5rem;
    }

    .DiabetesRiskCalculatorResultsTitleYMCLogo {
        width: 50px;
    }

    .DiabetesRiskCalculatorResultsTitleIcon {
        height: 35px;
        width: 35px;
    }

    .DiabetesRiskCalculatorPart2ButtonsFlex {
        flex-flow: row wrap;
        gap: 1rem;
    }

    .DiabetesRiskCalculatorPart2Button {
        width: 100%;
    }
}